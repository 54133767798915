import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import { dataVerification } from '../../../../../utils/formatChecks'
import './index.less'

const AboutContactUsMobile = () => {
  const [data, setData] = useState()
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [content, setContent] = useState('')
  const [validate_code, setValidateCode] = useState('')
  const [code, setCode] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取关于我们联系方式列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'footer_contact_us' });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getCode = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/auth/captchaImage");
      if (res.status === 10000) {
        setCode(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getSubmit = async () => {
    if (name === '') return message.error({ content: '姓名不能为空', style: { marginTop: '10vh' } })
    if (mobile === '') return message.error({ content: '联系电话不能为空', style: { marginTop: '10vh' } })
    if (dataVerification('tel', mobile)) return message.error({ content: '电话格式错误', style: { marginTop: '10vh' } })
    if (content === '') return message.error({ content: '留言内容不能为空', style: { marginTop: '10vh' } })
    if (validate_code === '') return message.error({ content: '验证码不能为空', style: { marginTop: '10vh' } })
    await getSubmitApi()
  }
  const getSubmitApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/advice/submit", {
        name,
        mobile,
        message: content,
        id_key: code.idKey,
        validate_code
      })
      if (res.status === 10000) {
        await message.success({ content: res?.message, style: { marginTop: '10vh' } })
        setName('')
        setMobile('')
        setContent('')
        setValidateCode('')
        getCode()
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getCode()
  }, [])

  return (
    <div className='about_contact_us_m'>
      <div className='title'>
        <span>联系我们</span>
        <span>Contact Us</span>
      </div>
      <div className='contact_us_list'>
        {
          data &&
          data?.Gallery_list &&
          data?.Gallery_list?.length > 0 &&
          data?.Gallery_list?.map((m,index) =>
            <div className={`row ${index===0?'margin_top':''}`} key={'contact_us_list_item_'+index}>
              <span>{m?.title}</span>
              <span>{m?.content}</span>
            </div>
          )
        }
      </div>
      <div className='contact_us_form'>
        <div className='input'>
          <span>姓名</span>
          <input value={name} placeholder='请填写姓名' onInput={(e) => { setName(e.target.value) }} />
        </div>
        <div className='input'>
          <span>联系电话</span>
          <input value={mobile} placeholder='请填写联系电话' maxLength={11} onInput={(e) => { setMobile(e.target.value) }} />
        </div>
        <div className='textarea'>
          <span>留言内容</span>
          <textarea value={content} placeholder='请填写留言内容' onInput={(e) => { setContent(e.target.value) }} />
        </div>
        <div className='input_code'>
          <div className='input'>
            <span>验证码</span>
            <input value={validate_code} placeholder='请填写验证码' maxLength={4} onInput={(e) => { setValidateCode(e.target.value) }} />
          </div>
          <div className='btn_code' onClick={getCode}>
            <Image src={code?.url} alt='' />
          </div>
        </div>
      </div>
      <div className='btn' onClick={getSubmit}>
        <span>提交留言</span>
      </div>
    </div>
  )
}

export default AboutContactUsMobile