import { useState, useEffect } from 'react'
import { message, Pagination } from 'antd'
import { useSelector } from 'react-redux'
import YzSelectBox from '../../../../../components/mobile/YzSelectBox'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const SuccessfulCaseSortMobile = ({goToTop=()=>{}}) => {
  const token = useSelector((state) => state?.App?.token)

  const [industryId, setIndustryId] = useState(0) // 选择的行业
  const [serviceId, setServiceId] = useState(0) // 选择的服务
  const [page, setPage] = useState(1)

  const [industryList, setIndustryList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const getIndustryList = async () => {  // 获取行业列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/case/tradeList"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            id: item?.id,
            title: item?.name
          }
        })
        setIndustryList([{id: '', title: '全部行业'},...target])
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getServiceList = async () => { // 获取服务列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/case/serviceList"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            id: item?.id,
            title: item?.name
          }
        })
        setServiceList([{id: '', title: '全部服务'},...target])
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getIndustryList()
    getServiceList()
  }, [])
  const [data, setData] = useState([]) // 成功案例列表
  const [dataLength, setDataLength] = useState(0)
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取成功案例列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/case/list"+`?t=${new Date().getTime()}`, {
        token,
        trade_id: industryId,
        service_id: serviceId,
        page,
        pers: 6
      })
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            id: item?.id,
            icon: item?.pic,
            title: item?.name,
            content: item?.content
          }
        })
        setDataLength(res?.count)
        setData([...target])
        goToTop(false)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page, industryId, serviceId])

  return (
    <>
      <div className='scsm_sort_m'>
        <div className='screen'>
          <div className='screen_item'>
            <YzSelectBox
              list={industryList}
              onChange={(e) => { setIndustryId(e) }}
            />
          </div>
          <div className='screen_item'>
            <YzSelectBox
              list={serviceList}
              onChange={(e) => { setServiceId(e) }}
            />
          </div>
        </div>
        <div className='result'>
          <span>当前筛选到 <b>{dataLength}</b> 个结果</span>
        </div>
        {/* 展示列表 */}
        {
          data &&
          data?.length > 0 &&
          <div className='list'>
            {
              data?.map((m, index) => 
                <div key={'psm_'+index} className='item' style={{ marginTop: index===0 && 0 }}>
                  <div className='psm_img'>
                    <Image src={m?.icon} alt='' />
                  </div>
                  <div className='item_box'>
                    <span className='item_title'>{m?.title}</span>
                    <div className='item_divider' />
                    <span className='item_content'>{m?.content}</span>
                  </div>
                </div>
              )
            }
          </div>
        }
        {/* 分页器 */}
        {
          data &&
          data?.length > 0 &&
          dataLength > 0 &&
          <div className='pagination'>
            <Pagination
              current={page}
              total={dataLength}
              pageSize={6}
              showQuickJumper={true}
              showSizeChanger={false}
              hideOnSinglePage={true}
              onChange={(pageNumber) => { setPage(pageNumber) }}
            />
          </div>
        }
      </div>
      {
        data &&
        data?.length === 0 &&
        <div style={{ flex: 1 }}>
          <YzEmptyMobile />
        </div>
      }
    </>
  )
}

export default SuccessfulCaseSortMobile