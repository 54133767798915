import { useState, useEffect } from 'react'
import Image from '../../Image'
import './index.less'

const YzSelectBox = ({
  list=[],
  onChange=() => {}
}) => {
  const [selectId, setSelectId] = useState('')
  const [isShow, setIsShow] = useState(false)
  useEffect(() => { // 点击空白处收起弹窗
    if(isShow) {
      window.addEventListener('click', () => {
        setIsShow(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
    // if(isShow) {
    //   window.addEventListener('scroll', () => {
    //     setIsShow(false)
    //   }, true)
    // } else {
    //   window.removeEventListener('scroll', () => {}, false)
    // }
  }, [isShow])

  return (
    <div
      className='yz_select_box'
      onClick={(e) => {
        e.stopPropagation()
        setIsShow(!isShow)
      }}
    >
      <span className='yz_select_box_item'>
        { list && list?.length > 0 && [list?.filter(f => f.id === selectId)?.map(m => m.title)] }
      </span>
      <Image src={require('./assets/icon_page@3x.png')} alt='' />
      {isShow &&
      <div className='yz_select_list'>
        {
          list &&
          list?.length > 0 &&
          list?.map((m, index) =>
            <div
              key={'yz_select_item_'+index}
              className='yz_select_item'
              onClick={() => {
                setSelectId(m?.id)
                onChange(m?.id)
              }}
            >
              <span>{m?.title}</span>
            </div>
          )
        }
      </div>}
    </div>
  )
}

export default YzSelectBox