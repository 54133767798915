import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import YzBackground from '../../../../../components/pc/YzBackground'
import ProductSort from '../ProductSort'
import Footer from '../../../../../components/pc/Footer'
import BuoyBox from '../../../../../components/pc/BuoyBox'
import './index.less'

const ProductSection = () => {
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '清宜资讯').map(m => m.backgroud)

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])
  
  return (
    <section ref={boxRef} className='product_section'>
      <div className='product_box'>
        <YzBackground
          title='News'
          content='新闻动态'
          backgroundImage={backgroud}
          isShowButton={false}
        />
        <ProductSort goToTop={goToTop} />
        <Footer />
        <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default ProductSection