import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeNewsMobile = () => {
  const navigate = useNavigate()
  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [category_id] = menuList.filter(f => f.title === '清宜资讯').map(m => m.category_id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/article/list"+`?t=${new Date().getTime()}`, { category_id, page: 1, pers: 3 });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='home_news_m'>
      <div className='title'>
        <span>新闻动态</span>
        <span>News</span>
      </div>
      <div className='list'>
        {
          data &&
          data?.length > 0 &&
          data?.map((m, index) => 
            <div
              key={'hnm_item_'+index}
              className={`item ${index===0?'margin_top':''}`}
              onClick={() => {
                navigate('/product/details?id='+m?.id)
              }}
            >
              <Image src={m?.cover} alt='' />
              <span>{m?.title}</span>
            </div>
          )
        }
      </div>
      {data && data?.length > 0 &&
      <div
        className='btn'
        onClick={() => {
          navigate('/product')
        }}
      >
        <span>查看更多</span>
      </div>}
    </div>
  )
}

export default HomeNewsMobile