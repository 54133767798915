import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import YzBackgroundMobile from '../../../../../components/mobile/YzBackgroundMobile'
import ProductSortMobile from '../ProductSortMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import BuoyBoxMobile from '../../../../../components/mobile/BuoyBoxMobile'
import './index.less'

const ProductSectionMobile = () => {
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '清宜资讯').map(m => m.backgroud)

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='product_section_m'>
      <div className='product_section_m_box'>
        <YzBackgroundMobile
          title='News'
          content={`新闻动态`}
          backgroundImage={backgroud}
          isShowButton={false}
        />
        <ProductSortMobile goToTop={goToTop} />
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default ProductSectionMobile