import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import { message } from 'antd'
import YzBackgroundMobile from '../../../../../components/mobile/YzBackgroundMobile'
import AboutUsIntroduceMobile from '../AboutUsIntroduceMobile'
import AboutCourseMobile from '../AboutCourseMobile'
import AboutHonorMobile from '../AboutHonorMobile'
import AboutContactUsMobile from '../AboutContactUsMobile'
import AUBAdvantageMobile from '../AUBAdvantageMobile'
import AUBTeamMobile from '../AUBTeamMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import BuoyBoxMobile from '../../../../../components/mobile/BuoyBoxMobile'
import HTTP from '../../../../../utils/Http'
import './index.less'

const AboutUsSectionMobile = () => {
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '关于我们').map(m => m.backgroud)

  const [data, setData] = useState([]) // 关于我们列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取关于我们列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/list"+`?t=${new Date().getTime()}`)
      if (res.status === 10000) {
        let target = res?.data?.filter(f => {
          return f?.key === 'company_profile' ||
          f?.key === 'develop_history' ||
          f?.key === 'footer_contact_us'
        })
        setData(target)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='about_us_section_m'>
      <div className='about_us_box_m'>
        <YzBackgroundMobile
          title='About US'
          content='关于我们'
          backgroundImage={backgroud}
          isShowButton={false}
        />
        <AboutUsIntroduceMobile data={data[0]} />
        <AboutCourseMobile data={data[1]} />
        <AboutHonorMobile />
        <AUBAdvantageMobile />
        <AUBTeamMobile />
        <AboutContactUsMobile data={data[2]} />
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default AboutUsSectionMobile