import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomePartnersMobile = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/partnersList"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const [content, setContent] = useState()
  const getContent = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'service_brands' });
      if (res.status === 10000) {
        setContent(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getContent()
  }, [])

  return (
    <div className='home_partners_m'>
      <div className='title'>
        <span>{ content && content?.name }</span>
        <span>Service Brands</span>
      </div>
      <span className='content'>
        { content && content?.value }
      </span>
      <div className='list'>
        {
          data &&
          data?.length > 0 &&
          data?.map((m, index) => 
            <div
              key={'kcmm_item_'+index}
              className='item'
            >
              <div className='item_box'>
                <span>{m?.name}</span>
                <div className='divider' />
                <div className='item_list'>
                  {
                    m?.partner_info && m?.partner_info?.length > 0 &&
                    m?.partner_info?.map((mm,mIndex) =>
                      <div className='item_list_item' key={'item_list_item_'+mIndex}>
                        <Image src={mm?.pic} alt='' />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default HomePartnersMobile