import { useState } from 'react'
import YzModalMobile from '../YzModalMobile'
import './index.less'

const YzBackgroundMobile = ({
  title='标题',
  content='内容',
  backgroundImage='',
  isShowButton=true
}) => {
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div
      className='yz_background_m'
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className='yz_background_box'>
        <div className='yz_background_foot'>
          <span className='yz_background_title'>{title}</span>
          <span className='yz_background_content'>{content}</span>
        </div>
        {isShowButton&&
        <div className='btn_consult' onClick={() => { setIsModalOpen(true) }}>
          <span>
            立即咨询
          </span>
        </div>}
      </div>
      {/* 立即咨询弹窗 */}
      <YzModalMobile open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default YzBackgroundMobile