import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { message } from 'antd'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import YzBackgroundMobile from '../../../../../components/mobile/YzBackgroundMobile'
import PDHeaderMobile from '../PDHeaderMobile'
import PDMainMobile from '../PDMainMobile'
import PDFooterMobile from '../PDFooterMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import HTTP from '../../../../../utils/Http'
import './index.less'
import PDBuoyBoxMobile from '../PDBuoyBoxMobile'

const PDSectionMobile = () => {
  const location = useLocation()
  const token = useSelector((state) => state?.App?.token)
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '清宜资讯').map(m => m.backgroud)

  const [data, setData] = useState() // 新闻动态详情
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取新闻动态详情
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/article/detail"+`?t=${new Date().getTime()}`, {
        token,
        id: location?.search.substring(4) ? location?.search.substring(4) - 0 : ''
      })
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [location?.search])

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='pdsm_section'>
      <div className='pdsm_box'>
        <YzBackgroundMobile
          title='News'
          content='新闻动态'
          backgroundImage={backgroud}
          isShowButton={false}
        />
        {
          data &&
          data?.Current ?
          <div className='pdm_main'>
            <PDHeaderMobile />
            <PDMainMobile Current={data?.Current} />
            <PDFooterMobile data={data} />
          </div> :
          <div style={{ flex: 1 }}>
            <YzEmptyMobile title='暂无数据' />
          </div>
        }
        <FooterMobile isAbout={false} />
        <PDBuoyBoxMobile
          isShowTop={scrollTop > clientHeight}
          onTop={goToTop}
          data={data && data.Current ? data.Current : {}}
        />
      </div>
    </section>
  )
}

export default PDSectionMobile