import { useState, useEffect } from 'react'
import Header from '../../components/pc/Header'
import SolutionSection from './components/pc/SolutionSection'
import HeaderMobile from '../../components/mobile/HeaderMobile'
import SolutionSectionMobile from './components/mobile/SolutionSectionMobile'
import { flexibleWidth } from '../../utils/flexible'
import './index.less'

const Solution = () => {
  let [plat, setPlat] = useState(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
  // 页面配置
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth) // 页面宽度
  useEffect(() => { // 页面宽度变化
    flexibleWidth()
    setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    setWindowWidth(document.documentElement.clientWidth)
    window.addEventListener('resize', () => {
      flexibleWidth()
      setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      setWindowWidth(document.documentElement.clientWidth)
    }, true)
  }, [])

  return (
    <div className='solution'>
      {
        !plat && windowWidth>540 ?
        <>
          <Header />
          <SolutionSection />
        </> :
        <>
          <HeaderMobile />
          <SolutionSectionMobile />
        </>
      }
    </div>
  )
}

export default Solution