import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { Popup } from 'antd-mobile'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import YzModalAddWebMobile from '../YzModalAddWebMobile'
import YzModalAddSortMobile from '../YzModalAddSortMobile'
import YzModalRemoveWebMobile from '../YzModalRemoveWebMobile'
import YzModalRemoveSortMobile from '../YzModalRemoveSortMobile'
import YzModalEditSortMobile from '../YzModalEditSortMobile'
import YzModalEditWebMobile from '../YzModalEditWebMobile'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import config from '../../../../../config'
import './index.less'

const Item = ({item, index}) => {
  return (
    <Draggable draggableId={'column_'+item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`web_box_item_list_item ${snapshot.isDragging?'green':''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Image
            className='icon'
            src={`${item?.url}/favicon.ico`}
            spare={`${config.domain}/favicon.ico`}
            alt=''
          />
          <span>{item?.name}</span>
          <Image className='right_top_img' src={require('./assets/icon_sort@3x.png')} alt=''/>
        </div>
      )}
    </Draggable>
  )
}

const Row = ({ row, list, index }) => {
  return (
    <Draggable draggableId={'row_'+row.id+''} index={index}>
      {(provided) =>
        <div className='web_box_list_1' ref={provided.innerRef} {...provided.draggableProps}>
          <div className='web_box_item_1'>
            <div id={`web_box_item_title_${row.id}`} className='web_box_item_title' {...provided.dragHandleProps}>
              <div className='divider' />
              <span>{row?.name}</span>
              <div className='icons'>
                <Image src={require('./assets/icon_sort@3x.png')} alt=''/>
              </div>
            </div>
            <Droppable droppableId={'row_'+row.id+''}>
              {(provided, snapshot) => (
                <div
                  className={`web_box_item_list ${snapshot.isDraggingOver?'blue':''}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  { list.map(((m, index) => <Item key={'item_'+m.id} item={m} index={index} />)) }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      }
    </Draggable>
  )
}

const WebNavigationMainMobile = () => {
  const token = useSelector(state => state?.App?.token)
  const userInfo = useSelector(state => state?.User?.userInfo)

  const [isOpenBtn, setIsOpenBtn] = useState(-1) // 增删改部分按钮状态
  const [isShowPopup, setIsShowPopup] = useState(false) // 是否弹出抽屉菜单
  const [activeIndex, setActiveIndex] = useState(0)  // 选择的侧边栏项
  // 添加网站
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false) // 显示添加网站弹窗
  const [isModalOpenAddSort, setIsModalOpenAddSort] = useState(false) // 显示添加网站分类弹窗
  // 编辑网站
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false) // 显示编辑网站弹窗
  const [nameEdit, setNameEdit] = useState('') // 当前编辑网站名称
  const [linkEdit, setLinkEdit] = useState('') // 当前编辑网站链接
  const [idSortEdit, setIdSortEdit] = useState(-1) // 当前编辑所属分类id
  const [indexSortEdit, setIndexSortEdit] = useState(-1) // 当前编辑网站分类index
  const [indexEdit, setIndexEdit] = useState(-1) // 当前编辑网站index
  const [isModalOpenEditSort, setIsModalOpenEditSort] = useState(false) // 显示编辑网站分类弹窗
  const [nameEditSort, setNameEditSort] = useState('') // 当前编辑网站分类名称
  // 删除网站
  const [isModalOpenRemove, setIsModalOpenRemove] = useState(false) // 显示删除网站弹窗
  const [isModalOpenRemoveSort, setIsModalOpenRemoveSort] = useState(false) // 显示删除网站分类弹窗
  const [idRemove, setIdRemove] = useState('') // 当前删除网站id
  const [idSortRemove, setIdSortRemove] = useState('') // 当前删除网站分类id
  const [nameRemove, setNameRemove] = useState('') // 当前删除网站名称

  // 内容列表
  const [data, setData] = useState([]) // 网址导航分类树
  const [userSort, setUserSort] = useState([
    {
      id: 30,
      name: '测试1',
      info: [
        { id: 100, name: '测试1' },
        { id: 101, name: '测试2' },
        { id: 102, name: '测试3' },
        { id: 103, name: '测试4' }
      ]
    },
    {
      id: 31,
      name: '测试2',
      info: []
    },
    {
      id: 32,
      name: '测试3',
      info: []
    }
  ]) // 用户专属分类列表
  const [systemSort, setSystemSort] = useState([]) // 系统专属分类列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取网址导航分类树
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/webNavigation/webNavigationTree'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        // console.log(res?.data)
        setData(res?.data)
        if (res?.data && res?.data?.length>0 && res?.data[0]) {
          // console.log(res?.data[0]?.child_info)
          setUserSort(res?.data[0]?.child_info)
        }
        if(res?.data && res?.data?.length>0) {
          let arr = [...res?.data]
          let arr2 = arr?.splice(1,arr?.length)
          // console.log(arr2)
          setSystemSort(arr2)
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const getEditUserWebApi = async (arr) => {  // 编辑网址导航 
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.post('/api/v1/webUser/updateWebInfo?token='+token, {
        data: arr
      }, undefined, token, undefined)
      if (res.status === 10000) {
        message.success({ content: res?.message, style: { marginTop: '10vh' } })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const editClick = () => { // 编辑网站
    let arr = [...userSort]
    arr[indexSortEdit].is_change = 1
    arr[indexSortEdit].info[indexEdit].is_change = 1
    arr[indexSortEdit].info[indexEdit].name = nameEdit
    arr[indexSortEdit].info[indexEdit].url = linkEdit
    arr[indexSortEdit].info[indexEdit].category_id = idSortEdit
    // 判断当前是否切换分类，切换则执行切换步骤
    if (arr.map(m=>m.id).indexOf(idSortEdit)!==-1&&arr.map(m=>m.id).indexOf(idSortEdit)!==indexSortEdit) {
      arr[arr.map(m=>m.id).indexOf(idSortEdit)].info.push(arr[indexSortEdit].info[indexEdit])
      arr[arr.map(m=>m.id).indexOf(idSortEdit)].is_change = 1
      arr[indexSortEdit].info.splice(indexEdit,1)
    }
    setUserSort(arr)
    setIsModalOpenEdit(false)
  }
  const editSortClick = () => { // 编辑网站分类
    let arr = [...userSort]
    arr[indexSortEdit].is_change = 1
    arr[indexSortEdit].name = nameEditSort
    setUserSort(arr)
    setIsModalOpenEditSort(false)
  }
  const removeClick = () => { // 删除网站
    let arr = [...userSort]
    arr[arr.map(m=>m.id).indexOf(idSortRemove)]
    .info[arr[arr.map(m=>m.id).indexOf(idSortRemove)]
    .info.map(m=>m.id).indexOf(idRemove)].is_change = 1
    setUserSort(arr)
    setIsModalOpenRemove(false)
  }
  const removeSortClick = () => { // 删除网站分类
    let arr = [...userSort]
    arr[arr.map(m=>m.id).indexOf(idSortRemove)].is_change = 1
    setUserSort(arr)
    setIsModalOpenRemoveSort(false)
  }
  // 网站排序
  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result
    console.log(destination, source, draggableId.split('_')[1], type)
    if (!destination) return;
    // 分类之间的切换
    if (type === 'row') {
      if (destination.index === source.index) return;
      const arr = [...userSort]
      const sourceItem = arr[source.index] // 当前网站分类项
      arr.splice(source.index, 1) // 删除当前项
      arr.splice(destination.index, 0, sourceItem) // 在目标分类项的位置添加当前分类项
      console.log(arr) // 新的data数据
      return setUserSort(arr)
    }
    // 网站分类项自己的网站之间进行排序
    if (destination.droppableId === source.droppableId) {
      if (destination.index === source.index) return;
      const arr = [...userSort]
      const sourceSortIndex = arr.map(m=>m.id).indexOf(source.droppableId.split('_')[1]-0) // 当前分类index
      const sourceSortList = arr[sourceSortIndex].info // 当前分类数组
      const sourceIndex = sourceSortList.map(m=>m.id).indexOf(draggableId.split('_')[1]-0) // 当前网站项index
      const sourceItem = sourceSortList[sourceIndex] // 当前网站项
      sourceSortList.splice(source.index, 1) // 删除当前项
      sourceSortList.splice(destination.index, 0, sourceItem) // 在目标项的位置添加当前项
      console.log(arr) // 新的data数据
      return setUserSort(arr)
    }
    // 网站分类项的网站相互之间进行排序
    const arr = [...userSort]
    const sourceSortIndex = arr.map(m=>m.id).indexOf(source.droppableId.split('_')[1]-0) // 当前分类index
    const sourceSortList = arr[sourceSortIndex].info // 当前分类数组
    const sourceIndex = sourceSortList.map(m=>m.id).indexOf(draggableId.split('_')[1]-0) // 当前网站项index
    const sourceItem = sourceSortList[sourceIndex] // 当前网站项
    sourceSortList.splice(source.index, 1) // 删除当前项
    const targetSortIndex = arr.map(m=>m.id).indexOf(destination.droppableId.split('_')[1]-0) // 目标分类index
    const targetSortList = arr[targetSortIndex].info // 目标分类数组
    targetSortList.splice(destination.index, 0, sourceItem) // 在目标分类数组中添加当前项
    console.log(arr) // 新的data数据
    setUserSort(arr)
  }
  useEffect(() => {
    if (
      !isModalOpenAdd&&
      !isModalOpenAddSort
    ) {
      getData()
    }
  }, [
    isModalOpenAdd,
    isModalOpenAddSort,
    isOpenBtn
  ])

  return (
    <div className='web_navigation_main_m'>
      <div className='btn_box'>
        <div className='btn_box_row'>
          <div
            className='btn btn_add'
            onClick={() => {
              if (!userInfo) return message.warning({
                content: '请先登录！登录后可使用专属导航页',
                style: { marginTop: '10vh' } 
              })
              setIsModalOpenAdd(true)
            }}
          >
            添加网站
          </div>
          <div
            className='btn margin_left'
            onClick={() => {
              if (!userInfo) return message.warning({
                content: '请先登录！登录后可使用专属导航页',
                style: { marginTop: '10vh' } 
              })
              setIsModalOpenAddSort(true)
            }}
          >
            添加分类
          </div>
        </div>
        <div className={`btn_box_row margin_top ${isOpenBtn===-1?'':'justify_content_end'}`}>
          {
            isOpenBtn===-1 ?
            <>
              <div
                className='btn'
                onClick={() => { setIsOpenBtn(0) }}
              >
                修改
              </div>
              <div
                className='btn margin_left'
                onClick={() => { setIsOpenBtn(1) }}
              >
                排序
              </div>
              <div
                className='btn margin_left'
                onClick={() => { setIsOpenBtn(2) }}
              >
                删除
              </div>
            </> :
            <div
              className='btn btn_operate'
              onClick={async () => {
                if (isOpenBtn===0) { // 编辑
                  let arr = [...userSort]
                  let arr2 =arr?.map((m,index)=>{
                    return {
                      id: m?.id,
                      sequence: index+1,
                      category: 1,
                      is_change: m?.is_change===1?1:2,
                      name: m?.name,
                      child_web_info: m?.info?.map((m2,index2) => {
                        return {
                          category_id: m?.id,
                          id: m2?.id,
                          sequence: index2+1,
                          category: 1,
                          is_change: m2?.is_change===1?1:2,
                          name: m2?.name,
                          url: m2?.url
                        }
                      })
                    }
                  })
                  // 判断是否做了操作
                  if (arr2.some(s=>s.is_change===1)) await getEditUserWebApi(arr2)
                }
                if (isOpenBtn===1) { // 排序
                  let arr = [...userSort]
                  let arr2 =arr?.map((m,index)=>{
                    return {
                      id: m?.id,
                      sequence: index+1,
                      category: 1,
                      is_change: 1,
                      name: m?.name,
                      child_web_info: m?.info?.map((m2,index2) => {
                        return {
                          category_id: m?.id,
                          id: m2?.id,
                          sequence: index2+1,
                          category: 1,
                          is_change: 1,
                          name: m2?.name,
                          url: m2?.url
                        }
                      })
                    }
                  })
                  console.log(arr2);
                  await getEditUserWebApi(arr2)
                }
                if (isOpenBtn===2) { // 删除
                  let arr = [...userSort]
                  let arr2 =arr?.map((m,index)=>{
                    return {
                      id: m?.id,
                      sequence: index+1,
                      category: 2,
                      is_change: m?.is_change===1?1:2,
                      name: m?.name,
                      child_web_info: m?.info?.map((m2,index2) => {
                        return {
                          category_id: m?.id,
                          id: m2?.id,
                          sequence: index2+1,
                          category: 2,
                          is_change: m2?.is_change===1?1:2,
                          name: m2?.name,
                          url: m2?.url
                        }
                      })
                    }
                  })
                  // 判断是否做了操作
                  if (
                    arr2.some(s=>s.is_change===1) ||
                    arr2.some(s=>s.child_web_info.some(ss=>ss.is_change===1))
                  ) await getEditUserWebApi(arr2)
                }
                setIsOpenBtn(-1)
              }}
            >
              保存最终结果
            </div>
          }
        </div>
      </div>
      <div className='web_box'>
        {/* 用户专属分类 */}
        <>
        {/* 编辑 */}
        {
          userSort && userSort?.length>0 && isOpenBtn !== 1 && isOpenBtn !== 2 &&
          userSort?.map((m, index) =>
            <div
              key={'web_box_list_1_'+index}
              className='web_box_list_1'
            >
              <div className='web_box_item_1'>
                <div id={`web_box_item_title_${m.id}`} className='web_box_item_title'>
                  <div className='divider' />
                  <span>{m?.name}</span>
                  <div
                    className='icons'
                    onClick={() => {
                      if(isOpenBtn === 0){
                        setIndexSortEdit(index)
                        setNameEditSort(m?.name)
                        return setIsModalOpenEditSort(true)
                      }
                    }}
                  >
                    {isOpenBtn === 0 &&<Image src={require('./assets/icon_edit@3x.png')} alt=''/>}
                    {isOpenBtn === 1 &&<Image src={require('./assets/icon_sort@3x.png')} alt=''/>}
                  </div>
                </div>
                <div className='web_box_item_list'>
                  {
                    m?.info && m?.info?.length > 0 &&
                    m?.info?.map((m2, index2) =>
                      <div
                        // ${isOpenBtn===0||isOpenBtn===1?'doudong':''}
                        className={`web_box_item_list_item`}
                        key={'web_box_item_list_item_'+index2}
                        onClick={() => {
                          if (isOpenBtn===0) {
                            setIdSortEdit(m?.id)
                            setIndexSortEdit(index)
                            setNameEdit(m2?.name)
                            setLinkEdit(m2?.url)
                            setIndexEdit(index2)
                            return setIsModalOpenEdit(true)
                          }
                          if (m2?.url==='') return message.warning({
                            content: '该网址链接已过期或域名已更改',
                            style: { marginTop: '10vh' }
                          })
                          window.open(m2?.url, '_blank')
                        }}
                      >
                        <Image
                          className='icon'
                          src={`${m2?.url}/favicon.ico`}
                          spare={`${config.domain}/favicon.ico`}
                          alt=''
                        />
                        <span>{m2?.name}</span>
                        {isOpenBtn === 0 &&
                        <Image className='right_top_img' src={require('./assets/icon_edit@3x.png')} alt=''/>}
                        {isOpenBtn === 1 &&
                        <Image className='right_top_img' src={require('./assets/icon_sort@3x.png')} alt=''/>}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
        {/* 排序 */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='all_box' type='row'>
            {(provided) =>
              <div className='box' ref={provided.innerRef} {...provided.droppableProps}>
              {
                userSort && userSort?.length>0 && isOpenBtn === 1 &&
                userSort?.map((m, index) =>
                  <Row
                    key={'row_'+m.id}
                    index={index}
                    row={m}
                    list={m.info}
                  />
                )
              }
              {provided.placeholder}
              </div>
            }
          </Droppable>
        </DragDropContext>
        {/* 删除 */}
        {
          userSort && userSort?.length>0 && isOpenBtn === 2 &&
          userSort?.filter(f=>f.is_change===2||!f.is_change).map((m, index) => // 删除
            <div className='web_box_list_1' key={'web_box_list_1_'+index}>
              <div className='web_box_item_1'>
                <div id={`web_box_item_title_${m.id}`} className='web_box_item_title'>
                  <div className='divider' />
                  <span>{m?.name}</span>
                  <div
                    className='icons'
                    onClick={() => {
                      if (isOpenBtn===2) {
                        setIdSortRemove(m?.id)
                        setNameRemove(m?.name)
                        return setIsModalOpenRemoveSort(true)
                      }
                    }}
                  >
                    <Image src={require('./assets/icon_delete@3x.png')} alt=''/>
                  </div>
                </div>
                <div className='web_box_item_list'>
                  {
                    m?.info && m?.info?.length > 0 &&
                    m?.info?.filter(f=>f.is_change===2||!f.is_change).map((m2, index2) =>
                      <div
                        // doudong
                        className='web_box_item_list_item'
                        key={'web_box_item_list_item_'+index2}
                        onClick={() => {
                          if (isOpenBtn===2) {
                            setIdSortRemove(m?.id)
                            setIdRemove(m2?.id)
                            setNameRemove(m2?.name)
                            return setIsModalOpenRemove(true)
                          }
                          if (m2?.url==='') return message.warning({
                            content: '该网址链接已过期或域名已更改',
                            style: { marginTop: '10vh' }
                          })
                          window.open(m2?.url, '_blank')
                        }}
                      >
                        <Image
                          className='icon'
                          src={`${m2?.url}/favicon.ico`}
                          spare={`${config.domain}/favicon.ico`}
                          alt=''
                        />
                        <span>{m2?.name}</span>
                        <Image className='right_top_img' src={require('./assets/icon_delete@3x.png')} alt=''/>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
        </>
        {/* 系统专属分类 */}
        <>
        {
          systemSort && systemSort?.length>0 &&
          systemSort?.map((m1, index1) =>
            <div key={'web_box_list_'+index1}>
              {
                m1?.child_info && m1?.child_info?.length > 0 &&
                m1?.child_info?.map((m2, index2) =>
                  <div className='web_box_list_2' key={'web_box_list_2_'+index2}>
                    <div className='web_box_item_2'>
                      <div id={`web_box_item_title_${m1.id}_${m2.id}`} className='web_box_item_title'>
                        <div className='divider' />
                        <span>{m2?.name}</span>
                      </div>
                      <div className='web_box_item_list'>
                        {
                          m2?.info && m2?.info?.length > 0 &&
                          m2?.info?.map((m3, index3) =>
                            <div
                              className='web_box_item_list_item margin_top'
                              key={'web_box_item_list_item_'+index3}
                              onClick={() =>{
                                if (m3?.url==='') return message.warning({
                                  content: '该网址链接已过期或域名已更改',
                                  style: { marginTop: '10vh' }
                                })
                                window.open(m3?.url, '_blank')
                              }}
                            >
                              <Image
                                className='icon'
                                src={`${m3?.url}/favicon.ico`}
                                spare={`${config.domain}/favicon.ico`}
                                alt=''
                              />
                              <div className='content_box'>
                                <span>{m3?.name}</span>
                                <span>{m3?.content}</span>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
        </>
      </div>
      {/* 抽屉菜单 */}
      <Popup
        className='my_popup'
        visible={isShowPopup}
        onMaskClick={() => { setIsShowPopup(false) }}
        onClose={() => { setIsShowPopup(false) }}
        position='left'
        bodyStyle={{ display: 'flex', flexDirection: 'column', paddingTop: '1.28rem', width: '70vw' }}
        maskStyle={{ background: 'rgba(0,0,0,.5)' }}
      >
        <div className='popup_body'>
          {
            data &&
            data?.length > 0 &&
            data?.map((m1, index1) =>
              <div
                className='popup_item'
                key={'popup_item_'+index1}
                onClick={() => { setActiveIndex(index1) }}
              >
                <div className={`popup_item_header ${activeIndex===index1?'active':''}`}>
                  <Image src={activeIndex===index1?m1.icon1:m1.icon} alt='' />
                  <span>{ m1?.name }</span>
                </div>
                <div
                  className='popup_item_list'
                  style={{
                    height: (activeIndex===index1) ?
                      m1?.child_info?.length > 0 ? m1?.child_info?.length*1.12+'rem' : 0
                      : 0
                  }}
                >
                  
                  {
                    m1?.child_info &&
                    m1?.child_info?.length > 0 &&
                    m1?.child_info?.map((m2, index2) =>
                      <div
                        className='popup_item_list_item'
                        key={'popup_item_list_item_'+index2}
                        onClick={() => {
                          setIsShowPopup(false)
                          if (index1===0) {
                            document.querySelector(`#web_box_item_title_${m2.id}`).scrollIntoView({
                              behavior: 'smooth', 
                              block: 'start',
                              inline: 'nearest'
                            })
                          } else {
                            document.querySelector(`#web_box_item_title_${m1.id}_${m2.id}`).scrollIntoView({
                              behavior: 'smooth', 
                              block: 'start',
                              inline: 'nearest'
                            })
                          }
                        }}
                      >
                        {m2?.name}
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
      </Popup>
      {/* 抽屉菜单弹出按钮 */}
      <div className='btn_menu_popup' onClick={() => { setIsShowPopup(true) }}>
        <Image src={require('./assets/icon_menu_m@3x.png')} alt='' />
      </div>
      {/* 弹窗 */}
      <>
        {/* 添加网站弹窗 */}
        <YzModalAddWebMobile
          open={isModalOpenAdd}
          token={token}
          onCancel={() => setIsModalOpenAdd(false) }
        />
        {/* 添加网站分类弹窗 */}
        <YzModalAddSortMobile
          open={isModalOpenAddSort}
          token={token}
          onCancel={() => setIsModalOpenAddSort(false) }
        />
        {/* 编辑网站弹窗 */}
        <YzModalEditWebMobile
          open={isModalOpenEdit}
          token={token}
          nameEdit={nameEdit}
          linkEdit={linkEdit}
          sortEdit={idSortEdit}
          setNameEdit={setNameEdit}
          setLinkEdit={setLinkEdit}
          setSortEdit={setIdSortEdit}
          onClick={editClick}
          onCancel={() => setIsModalOpenEdit(false) }
        />
        {/* 编辑网站分类弹窗 */}
        <YzModalEditSortMobile
          open={isModalOpenEditSort}
          nameEditSort={nameEditSort}
          setNameEditSort={setNameEditSort}
          onClick={editSortClick}
          onCancel={() => setIsModalOpenEditSort(false) }
        />
        {/* 删除网站弹窗 */}
        <YzModalRemoveWebMobile
          open={isModalOpenRemove}
          nameRemove={nameRemove}
          onClick={removeClick}
          onCancel={() => setIsModalOpenRemove(false) }
        />
        {/* 删除网站分类弹窗 */}
        <YzModalRemoveSortMobile
          open={isModalOpenRemoveSort}
          nameRemove={nameRemove}
          onClick={removeSortClick}
          onCancel={() => setIsModalOpenRemoveSort(false) }
        />
      </>
    </div>
  )
}

export default WebNavigationMainMobile