import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { setCommonConfig } from './store/app'
import HTTP from './utils/Http'
import Home from './pages/Home'
import Product from './pages/Product'
import ProductDetails from './pages/ProductDetails'
import Solution from './pages/Solution'
import SuccessfulCase from './pages/SuccessfulCase'
import CustomDevelopment from './pages/CustomDevelopment'
import AboutUs from './pages/AboutUs'
import Search from './pages/Search'
// import Login from './pages/Login'
// import LoginWeChar from './pages/LoginWeChat'
// import LoginPassword from './pages/LoginPassword'
// import LoginRegister from './pages/LoginRegister'
// import LoginTelBinding from './pages/LoginTelBinding'
// import Personal from './pages/Personal'
import WebNavigation from './pages/WebNavigation'
import AboutUsBusiness from './pages/AboutUsBusiness'

const App = () => {
  const dispatch = useDispatch()
  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [loading, setLoading] = useState(false)
  const getCommonApi = async () => { // 获取系统配置
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/common1/config'+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        // console.log(res?.data);
        const eleArr = document?.querySelectorAll('meta')
        if (res?.data && res?.data?.web_keywords && res?.data?.web_keywords !== '') eleArr[3].content = res?.data?.web_keywords
        if (res?.data && res?.data?.web_description && res?.data?.web_description !== '') eleArr[4].content = res?.data?.web_description
        await dispatch(setCommonConfig(res?.data))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getCommonApi()
  }, [])
  return (
    <div className="App">
      <BrowserRouter basename='/'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/*' element={<Navigate to='/' />} />
          {
            // 导航路由权限控制
            menuList.map((m) => {
              if (m?.link==='/product')
                return <Route key={m?.link} path='/product' element={<Product />} />
              if (m?.link==='/solution')
                return <Route key={m?.link} path='/solution' element={<Solution />} />
              if (m?.link==='/successful_case')
                return <Route key={m?.link} path='/successful_case' element={<SuccessfulCase />} />
              if (m?.link==='/custom_development')
                return <Route key={m?.link} path='/custom_development' element={<CustomDevelopment />} />
              if (m?.link==='/about_us')
                return <Route key={m?.link} path='/about_us' element={<AboutUs />} />
              if (m?.link==='/web_navigation')
                return <Route key={m?.link} path='/web_navigation' element={<WebNavigation />} />
            })
          }
          <Route path='/product/details' element={<ProductDetails />} />
          <Route path='/product/search' element={<Search />} />
          <Route path='/about_us/business' element={<AboutUsBusiness />} />
          {/* <Route path='/login' element={<Login />} /> */}
          {/* <Route path='/login/wechat' element={<LoginWeChar />} /> */}
          {/* <Route path='/login/tel_binding' element={<LoginTelBinding />} /> */}
          {/* <Route path='/login/password' element={<LoginPassword />} /> */}
          {/* <Route path='/register' element={<LoginRegister />} /> */}
          {/* <Route path='/personal' element={<Personal />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App
