import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'

const FooterMobile = ({ isAbout=true }) => {
  // 系统 
  const commonConfig = useSelector(state => state?.App?.commonConfig) // 系统配置
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取关于我们列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/list"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        let target = res?.data?.filter(f => {
          return f?.key === 'mobile' ||
          f?.key === 'email' ||
          f?.key === 'location' ||
          f?.key === 'index_code_upload'
        })
        setData(target)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <footer className='footer_m'>
      <div className='m_main'>
        {isAbout &&
        <div className='m_about'>
          <span className='m_about_title'>联系我们</span>
          <div className='m_about_link' style={{ marginTop: 0 }}>
            <Image src={require('./assets/icon_tel@3x.png')} alt='' />
            <span>{data && data?.length > 0 && data[0]?.value}</span>
          </div>
          <div className='m_about_link'>
            <Image src={require('./assets/icon_email@3x.png')} alt='' />
            <span>{data && data?.length > 0 && data[1]?.value}</span>
          </div>
          <div className='m_about_link'>
            <Image src={require('./assets/icon_address@3x.png')} alt='' />
            <span>{data && data?.length > 0 && data[2]?.value}</span>
          </div>
        </div>}
        <div className={`m_code ${!isAbout&&'margin_top0'}`}>
          {
            data &&
            data?.length > 0 &&
            data[3] &&
            data[3]?.Gallery_list &&
            data[3]?.Gallery_list?.length > 0 &&
            data[3]?.Gallery_list?.map((m, index) =>
              <div key={'m_code_item_'+index} className='m_code_item'>
                <Image src={m?.pic} alt='' />
                <span>{m?.title}</span>
              </div>
            )
          }
        </div>
      </div>
      <div className='m_footer'>
        <div className='m_content1'>
          <Image src={require('./assets/icon_icp@3x.png')} alt='' />
          <span
            onClick={() => {
              window.open(`https://www.beian.gov.cn/portal/index.do`, '_blank')
            }}
          >
            { commonConfig && commonConfig?.web_garecordnum }
          </span>
        </div>
        <span
          className='m_content2'
          onClick={() => {
            window.open(`https://beian.miit.gov.cn/#/Integrated/index`, '_blank')
          }}
        >
          { commonConfig && commonConfig?.web_recordnum }
        </span>
      </div>
    </footer>
  )
}

export default FooterMobile