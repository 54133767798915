import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeHonor = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'honors' });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='home_honor'>
      <div className='main_box'>
        <div className='title'>
          <span>{data && data.name}</span>
          <span>Honors and Awards</span>
        </div>
        <div className='content'>
          <div className='map'>
            <Image className='map_img' src={require('./assets/img_home_qualification@2x.png')} alt='' />
          </div>
          <div className='information'>
            <span className='label'>
              {data && data.value}
            </span>
            <div className='icon_list'>
              {
                data &&
                data?.Gallery_list &&
                data?.Gallery_list?.length > 0 &&
                data?.Gallery_list?.map((m,index) => 
                  <div className='icon_item' key={'icon_item_'+index}>
                    <div
                      className='icon_box'
                      style={{
                        paddingLeft: index%2!==0?'.0625rem':0,
                        paddingRight: index%2===0?'.0625rem':0
                      }}
                    >
                      <div className='icon_biaoji' />
                      <span className='icon_title'>{ m?.title }</span>
                    </div>
                  </div>
                )
              }
            </div>
            <div
              className='btn'
              onClick={() => {
                navigate('/about_us')
              }}
            >
              <span>查看更多</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHonor