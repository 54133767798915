import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuList: localStorage?.getItem('menuList') &&
              localStorage?.getItem('menuList') !== '' ?
              JSON.parse(localStorage?.getItem('menuList')) : []
  },
  reducers: {
    setMenuList: (state, action) => {
      state.menuList = action.payload
      localStorage.setItem('menuList', JSON.stringify(action.payload))
    }
  }
})

export const {
  setMenuList
} = menuSlice.actions

export default menuSlice.reducer