import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomePartners = () => {
  const [data, setData] = useState([]) // 合作伙伴列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取合作伙伴列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/partnersList"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const [content, setContent] = useState()
  const getContent = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'service_brands' });
      if (res.status === 10000) {
        setContent(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getContent()
  }, [])

  return (
    <div className='home_partners'>
      <div className='main_box'>
        <div className='title'>
          <span>{ content && content?.name }</span>
          <span>Service Brands</span>
          <span>{ content && content?.value }</span>
        </div>
        <div className='content'>
          <div className='partners_list'>
            {
              data &&
              data?.length > 0 &&
              data?.map((m, index) =>
                <div className={`partners_item ${index===0?'margin_left':''}`} key={'partnerst_'+index}>
                  <span>{m?.name}</span>
                  <div className='divider' />
                  <div className='partners_item_imgs'>
                  {
                    m?.partner_info &&
                    m?.partner_info?.length > 0 &&
                    m?.partner_info?.map((mm,mIndex) =>
                      <Image key={'partners_item_img_'+mIndex} className='partners_img' src={mm?.pic} alt='' />
                    )
                  }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePartners