import { useState, useEffect } from 'react'
import { Modal, message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const YzModalAddWebMobile = ({
  token='',
  open=false,
  onCancel=() => {}
}) => {

  const [name, setName] = useState('') // 网站名称
  const [link, setLink] = useState('') // 网站链接
  const [isOpenSort, setIsOpenSort] = useState(false) // 是否显示所属分类下拉框
  const [sort, setSort] = useState(-1) // 所属分类id
  const [sortList, setSortList] = useState([]) // 所属分类列表

  const [loading, setLoading] = useState(false)
  const getUserCategoryListApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/webUser/getUserCategoryList'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        setSortList(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getSave = async () => {
    if (name === '') return message.error({ content: '网站名称不能为空', style: { marginTop: '10vh' } })
    if (link === '') return message.error({ content: '网站链接不能为空', style: { marginTop: '10vh' } })
    if (
      !(new RegExp(/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/).test(link))
    ) return message.error({
      content: '网站链接格式有误',
      style: { marginTop: '10vh' }
    })
    if (sort === -1) return message.error({ content: '请选择所属分类', style: { marginTop: '10vh' } })
    await getAddWebApi()
  }
  const getAddWebApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/webUser/addWeb'+`?t=${new Date().getTime()}`, {
        token,
        name,
        url: link,
        category_id: sort
      })
      if (res.status === 10000) {
        message.success({
          content: res?.message,
          style: { marginTop: '10vh' },
          success: onCancel()
        })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  
  useEffect(() => {
    if (open) getUserCategoryListApi()
  }, [open])

  return (
    <Modal
      className='yz_modal_add_web_m'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={() => {
        onCancel()
        setName('')
        setLink('')
        setSort(-1)
        setIsOpenSort(false)
      }}
    >
      <div className='modal_header_web'>
        <span className='modal_title_web'>
          添加网站
        </span>
        <div className='modal_close_web' onClick={onCancel}>
          <Image className='modal_close_img_web' src={require('./assets/t_icon_top_close@3x.png')} alt='' />
        </div>
      </div>
      <div className='modal_body_web'>
        <div className='web_input_box'>
          <span>网站名称</span>
          <div className='web_input_box_main'>
            <input
              placeholder='输入网站名称'
              value={name}
              onInput={(e) => { setName(e?.target?.value) }}
            />
          </div>
        </div>
        <div className='web_input_box'>
          <span>网站链接</span>
          <div className='web_input_box_main'>
            <input
              placeholder='输入网站链接'
              value={link}
              onInput={(e) => { setLink(e?.target?.value) }}
            />
          </div>
        </div>
        <div className='web_select_box'>
          <span>所属分类</span>
          <div className='web_select_box_main' onClick={() => { setIsOpenSort(!isOpenSort) }}>
            <span style={{ color: sort===-1?'#999':'#171717'  }}>
              {/* 请选择分类 */}
              {
                sort === -1 ? '请选择分类' :
                sortList && sortList?.length > 0 && sortList.filter(f=>f.id===sort)?.map(m=>m?.name)
              }
            </span>
            <Image src={require('./assets/icon_page@3x.png')} alt='' />
            <div
              className='web_select_box_main_content'
              style={{
                height: isOpenSort?1.25*sortList?.length+.426666*2+'rem':0,
                padding: isOpenSort?'.426666rem 0':0
              }}
            >
              {
                sortList && sortList?.length > 0 &&
                sortList?.map((m, index) =>
                  <div
                    key={'web_select_box_main_content_item_'+index}
                    className={`web_select_box_main_content_item ${sortList.map(m=>m?.id)?.indexOf(sort)===index?'active':''}`}
                    onClick={() => { setSort(m?.id) }}
                  >
                    {m?.name}
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className='web_btn' onClick={getSave}>
          <span>保存</span>
        </div>
      </div>
    </Modal>
  )
}

export default YzModalAddWebMobile