import { useState } from 'react'
import { Modal, message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const YzModalAddSortMobile = ({
  token='',
  open=false,
  onCancel=() => {}
}) => {

  const [name, setName] = useState('') // 分类名称

  const [loading, setLoading] = useState(false)
  const getSave = async () => {
    if (name === '') return message.error({ content: '分类名称不能为空', style: { marginTop: '10vh' } })
    await getAddSortApi()
  }
  const getAddSortApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/webUser/addWebCategory'+`?t=${new Date().getTime()}`, {
        token,
        name
      })
      if (res.status === 10000) {
        message.success({
          content: res?.message,
          style: { marginTop: '10vh' },
          success: onCancel()
        })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      className='yz_modal_add_web_m'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={() => {
        onCancel()
        setName('')
      }}
    >
      <div className='modal_header_web'>
        <span className='modal_title_web'>
          添加分类
        </span>
        <div className='modal_close_web' onClick={onCancel}>
          <Image className='modal_close_img_web' src={require('./assets/t_icon_top_close@3x.png')} alt='' />
        </div>
      </div>
      <div className='modal_body_web'>
        <div className='web_input_box'>
          <span>分类名称</span>
          <div className='web_input_box_main'>
            <input
              placeholder='输入分类名称'
              value={name}
              onInput={(e) => { setName(e?.target?.value) }}
            />
          </div>
        </div>
        <div className='web_btn' onClick={getSave}>
          <span>保存</span>
        </div>
      </div>
    </Modal>
  )
}

export default YzModalAddSortMobile