import { useState } from 'react'
import './index.less'

const YzSortRowList = ({
  className='',
  title='分类',
  list=[],
  onChange=() => {}
}) => {
  const [index, setIndex] = useState(0)

  return (
    <div className={`yz_sort_row_list ${className!=='' && className}`}>
      <span>{title}：</span>
      <div className='yz_sort_row_list_list'>
        {
          list?.length > 0 &&
          list?.map((m,i) =>
            <div
              className={`yz_sort_row_list_item ${i===index&&'yz_sort_row_list_item_active'}`}
              key={'yz_sort_row_list_item'+i}
              onClick={() => {
                onChange(m?.id)
                setIndex(i)
              }}
            >
              {m?.title}
            </div>
          )
        }
      </div>
    </div>
  )
}

export default YzSortRowList