import Image from '../../../../../components/Image'
import PDBuoyBox from '../PDBuoyBox'
import moment from 'moment'
import './index.less'

const PDMain = ({ Current }) => {
  return (
    <>
    <main className='pd_main'>
      <div className='pd_main_box'>
        <div className='pd_main_box_header'>
          <span className='title'>{Current?.title}</span>
          <div className='time'>
            <Image src={require('./assets/icon_time@2x.png')} />
            <span>{moment(Current?.release_at).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          <span className='jianjie'>{Current?.summary}</span>
        </div>
        <div className='pd_main_box_content' dangerouslySetInnerHTML={{ __html: Current?.content }} />
      </div>
      <PDBuoyBox data={Current ? Current : {}} />
    </main>
    </>
  )
}

export default PDMain