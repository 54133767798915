import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeCustomDevelopment = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([]) // 核心业务列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取核心业务列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/solution/coreBusiness"+`?t=${new Date().getTime()}`, { page: 1, pers: 8 });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const [content, setContent] = useState()
  const getContent = async () => {
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'core_business' });
      if (res.status === 10000) {
        setContent(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
    }
  }
  useEffect(() => {
    getData()
    getContent()
  }, [])

  return (
    <div className='home_custom_development'>
      <div className='main_box'>
        <div className='title'>
          <span>{ content && content?.name }</span>
          <span>Core Business</span>
          <span>{ content && content?.value }</span>
        </div>
        <div className='content'>
          <div
            className='custom_development_list'
            style={{ justifyContent: data && data?.length > 1 ? 'space-between' : 'center' }}
          >
            {
              data &&
              data?.length > 0 &&
              data?.map((m, index) =>
                <div
                  className='custom_development_item'
                  key={'custom_development_'+index}
                  onClick={() => {
                    navigate('/custom_development')
                  }}
                >
                  <Image className='custom_development_img' src={m?.pic} alt='' />
                  <div className='custom_development_box'>
                    <span className='custom_development_title'>{m?.title}</span>
                    <span className='custom_development_content' title={m?.content}>{m?.content}</span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCustomDevelopment