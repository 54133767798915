import { useNavigate } from 'react-router-dom'
import Image from '../../../../../components/Image'
import './index.less'

const PDFooterMobile = ({data}) => {
  const navigate = useNavigate()
  const { next_id, preview_id, Next, Preview } = data
  
  return (
    <footer className='pdh_footer_m'>
      <div className='pdh_footer_box'>
        <div className={`prev ${preview_id===0?'opacity':''}`}>
          <div
            className='btn btn_prev'
            onClick={() => {
              if(preview_id===0) return;
              navigate('/product/details?id='+preview_id)
            }}
          >
            <Image src={require('./assets/icon_news_r@3x.png')} alt='' />
            <span>上一篇</span>
          </div>
          <span>{Preview?.title}</span>
        </div>
        <div className={`next ${next_id===0?'opacity':''}`}>
          <div
            className='btn btn_next'
            onClick={() => {
              if(next_id===0) return;
              navigate('/product/details?id='+next_id)
            }}
          >
            <span>下一篇</span>
            <Image src={require('./assets/icon_news_l@3x.png')} alt='' />
          </div>
          <span>{Next.title}</span>
        </div>
      </div>
    </footer>
  )
}

export default PDFooterMobile