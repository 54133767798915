import { useEffect, useState } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const AUBCompanyMobile = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/place/locationList'+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='aub_company_m'>
      <span className='title'>公司分布</span>
      <div className='list'>
      {
        data && data?.length > 0 &&
        data?.map((m,index) =>
          <div className='item' key={'item_'+index}>
            <div className='item_box'>
              <div className='spot' />
              <div className='content'>
                <span>{m?.city}</span>
                <span>{m?.content}</span>
              </div>
            </div>
          </div>
        )
      }
      </div>
      <div className='map'>
        <Image src={require('./assets/t_img_business_gs@3x.png')} alt='' />
      </div>
    </div>
  )
}

export default AUBCompanyMobile