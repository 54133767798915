import { useRef, useState, useEffect } from 'react'
import HomeSwiper from '../HomeSwiper'
import HomeIntroduce from '../HomeIntroduce'
import HomeHonor from '../HomeHonor'
import HomeCustomDevelopment from '../HomeCustomDevelopment'
import HomePartners from '../HomePartners'
import HomeNews from '../HomeNews'
import HomeContactUs from '../HomeContactUs'
import Footer from '../../../../../components/pc/Footer'
import BuoyBox from '../../../../../components/pc/BuoyBox'
import './index.less'

const HomeSection = () => {
  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='home_section'>
      <div className='box'>
        <HomeSwiper />
        <HomeIntroduce />
        <HomeHonor />
        <HomeCustomDevelopment />
        <HomePartners />
        <HomeNews />
        <HomeContactUs />
        <Footer />
        <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default HomeSection