import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeIntroduceMobile = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'enterprise_profile' });
      if (res.status === 10000) {
        setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div className='home_introduce_m'>
        <div className='title'>
          <span>{data && data.name}</span>
          <span>Introduction</span>
        </div>
        <span className='content'>
          {data && data?.value}
        </span>
        <div className='icon_list'>
          {
            data &&
            data?.Gallery_list &&
            data?.Gallery_list?.length > 0 &&
            data?.Gallery_list?.map((m, index) => 
              <div key={'icon_item_'+index} className='icon_item'>
                <Image className='icon_img' src={m?.pic} alt=''  />
                <div className='icon_box'>
                  <span className='icon_title'>{m?.content}</span>
                  <span className='icon_content'>{m?.title}</span>
                </div>
              </div>
            )
          }
        </div>
        <div className='map'>
          <Image className='map_img' src={require('./assets/t_img_home_map@3x.png')} alt='' />
          <div className='map_content'>
            <div className='map_label'>
              <Image src={require('./assets/t_icon_home_map_star@3x.png')} alt='' />
              <span>公司分布</span>
            </div>
            <div className='map_label margin_top'>
              <div className='dot'/>
              <span>客户分布</span>
            </div>
          </div>
        </div>
      </div>
      <div className='divider' />
    </>
  )
}

export default HomeIntroduceMobile