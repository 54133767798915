import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import YzModalAddWeb from '../YzModalAddWeb'
import YzModalAddSort from '../YzModalAddSort'
import YzModalEditWeb from '../YzModalEditWeb'
import YzModalEditSort from '../YzModalEditSort'
import YzModalRemoveWeb from '../YzModalRemoveWeb'
import YzModalRemoveSort from '../YzModalRemoveSort'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import config from '../../../../../config'
import './index.less'

const WebNavigationMain = () => {
  const token = useSelector(state => state?.App?.token)
  const userInfo = useSelector(state => state?.User?.userInfo)

  // 侧边栏
  const [activeIndex, setActiveIndex] = useState(0) // 选择的侧边栏项
  const [isOpenBtn, setIsOpenBtn] = useState(-1) // 增删改部分按钮状态

  // 添加网站
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false) // 显示添加网站弹窗
  const [isModalOpenAddSort, setIsModalOpenAddSort] = useState(false) // 显示添加网站分类弹窗
  // 编辑网站
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false) // 显示编辑网站弹窗
  const [nameEdit, setNameEdit] = useState('') // 当前编辑网站名称
  const [linkEdit, setLinkEdit] = useState('') // 当前编辑网站链接
  const [idSortEdit, setIdSortEdit] = useState(-1) // 当前编辑所属分类id
  const [indexSortEdit, setIndexSortEdit] = useState(-1) // 当前编辑网站分类index
  const [indexEdit, setIndexEdit] = useState(-1) // 当前编辑网站index
  const [isModalOpenEditSort, setIsModalOpenEditSort] = useState(false) // 显示编辑网站分类弹窗
  const [nameEditSort, setNameEditSort] = useState('') // 当前编辑网站分类名称
  // 删除网站
  const [isModalOpenRemove, setIsModalOpenRemove] = useState(false) // 显示删除网站弹窗
  const [isModalOpenRemoveSort, setIsModalOpenRemoveSort] = useState(false) // 显示删除网站分类弹窗
  const [idRemove, setIdRemove] = useState(-1) // 当前删除网站id
  const [idSortRemove, setIdSortRemove] = useState(-1) // 当前删除网站分类id
  const [nameRemove, setNameRemove] = useState('') // 当前删除网站名称
  // 网站排序
  const [isSonOpen, setIsSonOpen] = useState(0) // 排序拖拽类型，1分类、2网站
  const [selectIndex, setSelectIndex] = useState(-1) // 当前选择项的index
  const [selectId, setSelectId] = useState(-1) // 当前选择项的ID
  const [selectItem, setSelectItem] = useState() // 当前选择项
  const [targetIndex, setTargetIndex] = useState(-1) // 目标项的index
  const [targetId, setTargetId] = useState(-1) // 目标项的ID
  const [selectSortIndex, setSelectSortIndex] = useState(-1) // 当前分类项的index *
  const [selectSortId, setSelectSortId] = useState(-1) // 当前分类项的ID *
  const [selectSortItem, setSelectSortItem] = useState() // 当前分类项 *
  const [targetSortIndex, setTargetSortIndex] = useState(-1) // 目标分类项的index *
  const [targetSortId, setTargetSortId] = useState(-1) // 目标分类项的index *

  // 内容列表
  const [data, setData] = useState([]) // 网址导航分类树
  const [userSort, setUserSort] = useState([]) // 用户专属分类列表
  const [systemSort, setSystemSort] = useState([]) // 系统专属分类列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取网址导航分类树
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/webNavigation/webNavigationTree'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        // console.log(res?.data)
        setData(res?.data)
        if (res?.data && res?.data?.length>0 && res?.data[0]) {
          // console.log(res?.data[0]?.child_info)
          setUserSort(res?.data[0]?.child_info)
        }
        if(res?.data && res?.data?.length>0) {
          let arr = [...res?.data]
          let arr2 = arr?.splice(1,arr?.length)
          // console.log(arr2)
          setSystemSort(arr2)
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getEditUserWebApi = async (arr) => {  // 编辑网址导航 
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.post('/api/v1/webUser/updateWebInfo?token='+token, {
        data: arr
      }, undefined, token, undefined)
      if (res.status === 10000) {
        message.success({ content: res?.message, style: { marginTop: '10vh' } })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const editClick = () => { // 编辑网站
    let arr = [...userSort]
    arr[indexSortEdit].is_change = 1
    arr[indexSortEdit].info[indexEdit].is_change = 1
    arr[indexSortEdit].info[indexEdit].name = nameEdit
    arr[indexSortEdit].info[indexEdit].url = linkEdit
    arr[indexSortEdit].info[indexEdit].category_id = idSortEdit
    // 判断当前是否切换分类，切换则执行切换步骤
    if (arr.map(m=>m.id).indexOf(idSortEdit)!==-1&&arr.map(m=>m.id).indexOf(idSortEdit)!==indexSortEdit) {
      arr[arr.map(m=>m.id).indexOf(idSortEdit)].info.push(arr[indexSortEdit].info[indexEdit])
      arr[arr.map(m=>m.id).indexOf(idSortEdit)].is_change = 1
      arr[indexSortEdit].info.splice(indexEdit,1)
    }
    setUserSort(arr)
    setIsModalOpenEdit(false)
  }
  const editSortClick = () => { // 编辑网站分类
    let arr = [...userSort]
    arr[indexSortEdit].is_change = 1
    arr[indexSortEdit].name = nameEditSort
    setUserSort(arr)
    setIsModalOpenEditSort(false)
  }
  const removeClick = () => { // 删除网站
    let arr = [...userSort]
    arr[arr.map(m=>m.id).indexOf(idSortRemove)]
    .info[arr[arr.map(m=>m.id).indexOf(idSortRemove)]
    .info.map(m=>m.id).indexOf(idRemove)].is_change = 1
    setUserSort(arr)
    setIsModalOpenRemove(false)
  }
  const removeSortClick = () => { // 删除网站分类
    let arr = [...userSort]
    arr[arr.map(m=>m.id).indexOf(idSortRemove)].is_change = 1
    setUserSort(arr)
    setIsModalOpenRemoveSort(false)
  }
  useEffect(() => {
    if (
      !isModalOpenAdd&&
      !isModalOpenAddSort
    ) {
      getData()
    }
  }, [
    isModalOpenAdd,
    isModalOpenAddSort,
    isOpenBtn
  ])

  return (
    <div className='web_navigation_main'>
      <main className='box_main'>
        <aside className='web_navigation_aside'>
          {
            data &&
            data?.length > 0 &&
            data?.map((m1, index1) =>
              <div className='aside_item' key={'aside_item_'+index1}>
                <div
                  className={`aside_item_box ${activeIndex===index1?'active':''}`}
                  onClick={() => { setActiveIndex(index1) }}
                >
                  <Image src={activeIndex===index1?m1.icon1:m1.icon} alt='' />
                  <span>{ m1?.name }</span>
                </div>
                <div
                  className='aside_item_box_list'
                  style={{
                    height: (activeIndex===index1) ?
                      m1?.child_info?.length > 0 ? m1?.child_info?.length*.2083333+.0520835+'rem' : 0
                      : 0
                    }}
                >
                  {
                    m1?.child_info &&
                    m1?.child_info?.length > 0 &&
                    m1?.child_info?.map((m2, index2) =>
                      <div
                        key={'aside_item_box_list_item_'+index2}
                        className={`aside_item_box_list_item ${index2===0?'margin_top':''}`}
                        onClick={() => {
                          if (index1===0) {
                            document.querySelector(`#user_anchor_point_${m2.id}`).scrollIntoView({
                              behavior: 'smooth', 
                              // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                              block: 'start',
                              // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                              inline: 'nearest'
                              // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                            })
                          } else {
                            document.querySelector(`#server_anchor_point_${m2.id}`).scrollIntoView({
                              behavior: 'smooth', 
                              // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
                              block: 'start',
                              // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
                              inline: 'nearest'
                              // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
                            })
                          }
                        }}
                      >
                        <span>{ m2?.name }</span>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
        </aside>
        <article className='web_navigation_article'>
          <header className='web_navigation_article_header'>
            <div className='web_navigation_article_header_left'>
              <div
                className='btn btn_add'
                onClick={() => {
                  if (!userInfo) return message.warning({
                    content: '请先登录！登录后可使用专属导航页',
                    style: { marginTop: '10vh' } 
                  })
                  setIsModalOpenAdd(true)
                }}
              >
                添加网站
              </div>
              <div
                className='btn margin_left'
                onClick={() => {
                  if (!userInfo) return message.warning({
                    content: '请先登录！登录后可使用专属导航页',
                    style: { marginTop: '10vh' } 
                  })
                  setIsModalOpenAddSort(true)
                }}
              >
                添加分类
              </div>
            </div>
            <div className='web_navigation_article_header_right'>
              {
                isOpenBtn===-1 ?
                <>
                  <div
                    className='btn'
                    onClick={() => {
                      if (!userInfo) return message.warning({
                        content: '请先登录！登录后可使用专属导航页',
                        style: { marginTop: '10vh' } 
                      })
                      setIsOpenBtn(0)
                    }}
                  >
                    修改
                  </div>
                  <div
                    className='btn margin_left'
                    onClick={() => {
                      if (!userInfo) return message.warning({
                        content: '请先登录！登录后可使用专属导航页',
                        style: { marginTop: '10vh' } 
                      })
                      setIsOpenBtn(1)
                    }}
                  >
                    排序
                  </div>
                  <div
                    className='btn margin_left'
                    onClick={() => {
                      if (!userInfo) return message.warning({
                        content: '请先登录！登录后可使用专属导航页',
                        style: { marginTop: '10vh' } 
                      })
                      setIsOpenBtn(2)
                    }}
                  >
                    删除
                  </div>
                </> :
                <div
                  className='btn btn_save'
                  onClick={async () => {
                    if (isOpenBtn===0) { // 编辑
                      let arr = [...userSort]
                      let arr2 =arr?.map((m,index)=>{
                        return {
                          id: m?.id,
                          sequence: index+1,
                          category: 1,
                          is_change: m?.is_change===1?1:2,
                          name: m?.name,
                          child_web_info: m?.info?.map((m2,index2) => {
                            return {
                              category_id: m?.id,
                              id: m2?.id,
                              sequence: index2+1,
                              category: 1,
                              is_change: m2?.is_change===1?1:2,
                              name: m2?.name,
                              url: m2?.url
                            }
                          })
                        }
                      })
                      // 判断是否做了操作
                      if (arr2.some(s=>s.is_change===1)) await getEditUserWebApi(arr2)
                    }
                    if (isOpenBtn===1) { // 排序
                      let arr = [...userSort]
                      let arr2 =arr?.map((m,index)=>{
                        return {
                          id: m?.id,
                          sequence: index+1,
                          category: 1,
                          is_change: 1,
                          name: m?.name,
                          child_web_info: m?.info?.map((m2,index2) => {
                            return {
                              category_id: m?.id,
                              id: m2?.id,
                              sequence: index2+1,
                              category: 1,
                              is_change: 1,
                              name: m2?.name,
                              url: m2?.url
                            }
                          })
                        }
                      })
                      await getEditUserWebApi(arr2)
                    }
                    if (isOpenBtn===2) { // 删除
                      let arr = [...userSort]
                      let arr2 =arr?.map((m,index)=>{
                        return {
                          id: m?.id,
                          sequence: index+1,
                          category: 2,
                          is_change: m?.is_change===1?1:2,
                          name: m?.name,
                          child_web_info: m?.info?.map((m2,index2) => {
                            return {
                              category_id: m?.id,
                              id: m2?.id,
                              sequence: index2+1,
                              category: 2,
                              is_change: m2?.is_change===1?1:2,
                              name: m2?.name,
                              url: m2?.url
                            }
                          })
                        }
                      })
                      // 判断是否做了操作
                      if (
                        arr2.some(s=>s.is_change===1) ||
                        arr2.some(s=>s.child_web_info.some(ss=>ss.is_change===1))
                      ) await getEditUserWebApi(arr2)
                    }
                    setIsOpenBtn(-1)
                  }}
                >
                  保存最终结果
                </div>
              }
            </div>
          </header>
          {
            // 用户专属分类
            userSort && userSort?.length>0 && isOpenBtn !== 2 ?
            userSort?.map((m, index) => // 修改/排序
              <div
                draggable={isOpenBtn===1&&isSonOpen!==2}
                className={`web_navigation_article_box ${targetSortIndex===index?'active':''}`}
                key={'web_navigation_article_box_'+index}
                onDragStart={(e) => { // 开始被拖拽时触发
                  e.stopPropagation()
                  setSelectSortIndex(index)
                  setSelectSortId(m?.id)
                  setSelectSortItem(m)
                  setIsSonOpen(1)
                }}
                onDragEnter={(e) => { // a拖到b上时触发
                  e.stopPropagation()
                  e.preventDefault()
                  setTargetSortIndex(index)
                  setTargetIndex(-1)
                  setTargetId(-1)
                  if(isSonOpen===2) return;
                  setTargetSortId(m?.id)
                }}
                onDragEnd={() => { // 松手时触发
                  if(isSonOpen===2) return;
                  // 利用splice 把原来的 放到 当前的位置前，然后删除原来的
                  if(selectSortId!==targetSortId) {
                    // 在目标项前添加
                    let arr = [...userSort]
                    arr.splice(selectSortIndex,1) // 删除开始项
                    arr.splice(targetSortIndex,0,selectSortItem) // 在指定位置创建开始项
                    setUserSort(arr)
                  }
                  setIsSonOpen(0)
                  setTargetSortIndex(-1)
                  setTargetIndex(-1)
                  setTargetId(-1)
                  setSelectSortIndex(-1)
                  setSelectIndex(-1)
                  setSelectId(-1)
                  setSelectItem()
                }}
              >
                <div id={`user_anchor_point_${m.id}`} className='web_navigation_article_box_title'>
                  <div className='divider' />
                  <span>{m?.name}</span>
                  <div
                    className='icons'
                    onClick={() => {
                      if(isOpenBtn === 0){
                        setIndexSortEdit(index)
                        setNameEditSort(m?.name)
                        return setIsModalOpenEditSort(true)
                      }
                    }}
                  >
                    {isOpenBtn === 0 && <Image src={require('./assets/icon_edit@2x.png')} alt=''/>}
                    {isOpenBtn === 1 && <Image src={require('./assets/icon_sort@2x.png')} alt=''/>}
                  </div>
                </div>
                <div className='web_navigation_article_box_list'>
                  {
                    m?.info && m?.info?.length > 0 &&
                    m?.info?.map((m2, index2) =>
                      <div
                        className='web_navigation_article_box_list_item'
                        key={'web_navigation_article_box_list_item_'+index2}
                      >
                        <div
                          draggable={isOpenBtn===1&&isSonOpen!==1}
                          onDragStart={(e) => { // 开始被拖拽时触发
                            e.stopPropagation()
                            setSelectSortIndex(index)
                            setSelectIndex(index2)
                            setSelectId(m2?.id)
                            setSelectItem(m2)
                            setIsSonOpen(2)
                          }}
                          onDragEnter={(e) => { // a拖到b上时触发
                            e.stopPropagation()
                            if(isSonOpen===1) return;
                            setTargetIndex(index2)
                            setTargetId(m2?.id)
                          }}
                          onDragEnd={() => { // 松手时触发
                            if(isSonOpen===1) return;
                            // 利用splice 把原来的 放到 当前的位置前，然后删除原来的
                            if(selectId!==targetId) {
                              if (targetId !== -1) {
                                // 在目标项前添加
                                let arr = [...userSort]
                                // arr[selectSortIndex].is_change = 1
                                // arr[targetSortIndex].is_change = 1
                                arr[selectSortIndex].info.splice(selectIndex,1) // 删除开始项
                                arr[targetSortIndex].info.splice(targetIndex,0,selectItem) // 在指定位置创建开始项
                                setUserSort(arr)
                              } else if (selectSortIndex !== targetSortIndex) {
                                // 在目标分类项最后添加
                                let arr = [...userSort]
                                // arr[selectSortIndex].is_change = 1
                                // arr[selectSortIndex].info[selectIndex].is_change = 1
                                arr[selectSortIndex].info.splice(selectIndex,1) // 删除开始项
                                arr[targetSortIndex].info.push(selectItem) // 在指定位置创建开始项
                                setUserSort(arr)
                              }
                            }
                            setSelectSortIndex(-1)
                            setSelectIndex(-1)
                            setSelectId(-1)
                            setSelectItem()
                            setTargetIndex(-1)
                            setTargetId(-1)
                            setTargetSortIndex(-1)
                            setIsSonOpen(0)
                          }}
                          className={
                            `web_navigation_article_box_list_item_box 
                            ${targetSortIndex===index&&targetIndex===index2?'active':''}
                            ${isOpenBtn===0||isOpenBtn===1?'doudong':''}
                            `
                          }
                          onClick={() =>{
                            if (isOpenBtn===0) {
                              setIdSortEdit(m?.id)
                              setIndexSortEdit(index)
                              setNameEdit(m2?.name)
                              setLinkEdit(m2?.url)
                              setIndexEdit(index2)
                              return setIsModalOpenEdit(true)
                            }
                            if (isOpenBtn===1) return;
                            if (m2?.url==='') return message.warning({
                              content: '该网址链接已过期或域名已更改',
                              style: { marginTop: '10vh' }
                            })
                            window.open(m2?.url, '_blank')
                          }}
                        >
                          <Image
                            className='box_img'
                            src={`${m2?.url}/favicon.ico`}
                            spare={`${config.domain}/favicon.ico`}
                            alt=''
                          />
                          <span>{m2?.name}</span>
                          {isOpenBtn === 0 &&
                          <Image className='p_img' src={require('./assets/icon_edit@2x.png')} alt=''/>}
                          {isOpenBtn === 1 &&
                          <Image className='p_img' src={require('./assets/icon_sort@2x.png')} alt=''/>}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            ) :
            userSort?.filter(f=>f.is_change===2||!f.is_change).map((m, index) => // 删除
              <div
                className='web_navigation_article_box'
                key={'web_navigation_article_box_'+index}
              >
                <div id={`user_anchor_point_${m.id}`} className='web_navigation_article_box_title'>
                  <div className='divider' />
                  <span>{m?.name}</span>
                  <div
                    className='icons'
                    onClick={() => {
                      if (isOpenBtn===2) {
                        setIdSortRemove(m?.id)
                        setNameRemove(m?.name)
                        return setIsModalOpenRemoveSort(true)
                      }
                    }}
                  >
                    <Image src={require('./assets/icon_delete@2x.png')} alt=''/>
                  </div>
                </div>
                <div className='web_navigation_article_box_list'>
                  {
                    m?.info && m?.info?.length > 0 &&
                    m?.info?.filter(f=>f.is_change===2||!f.is_change).map((m2, index2) =>
                      <div
                        className='web_navigation_article_box_list_item'
                        key={'web_navigation_article_box_list_item_'+index2}
                      >
                        <div
                          className='web_navigation_article_box_list_item_box doudong'
                          onClick={() =>{
                            if (isOpenBtn===2) {
                              setIdSortRemove(m?.id)
                              setIdRemove(m2?.id)
                              setNameRemove(m2?.name)
                              return setIsModalOpenRemove(true)
                            }
                            if (m2?.url==='') return message.warning({
                              content: '该网址链接已过期或域名已更改',
                              style: { marginTop: '10vh' }
                            })
                            window.open(m2?.url, '_blank')
                          }}
                        >
                          <Image
                            className='box_img'
                            src={`${m2?.url}/favicon.ico`}
                            spare={`${config.domain}/favicon.ico`}
                            alt=''
                          />
                          <span>{m2?.name}</span>
                          <Image className='p_img' src={require('./assets/icon_delete@2x.png')} alt=''/>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            // 系统专属分类
            systemSort && systemSort?.length>0 &&
            systemSort?.map((m1,index1) =>
              <div key={'web_navigation_article_box_item_'+index1}>
                {
                  m1?.child_info && m1?.child_info?.length > 0 &&
                  m1?.child_info?.map((m2, index2) => 
                  <div className='web_navigation_article_box2' key={'web_navigation_article_box2'+index2}>
                    <div id={`server_anchor_point_${m2.id}`} className='web_navigation_article_box_title margin_top'>
                      <div className='divider' />
                      <span>{m2?.name}</span>
                    </div>
                    <div className='web_navigation_article_box_list'>
                      {
                        m2?.info && m2?.info?.length > 0 &&
                        m2?.info?.map((m3, index3) =>
                          <div
                            className='web_navigation_article_box_list_item'
                            key={'web_navigation_article_box_list_item_'+index3}
                          >
                            <div
                              className='web_navigation_article_box_list_item_box'
                              onClick={() =>{
                                if (m3?.url==='') return message.warning({
                                  content: '该网址链接已过期或域名已更改',
                                  style: { marginTop: '10vh' }
                                })
                                window.open(m3?.url, '_blank')
                              }}
                            >
                              <Image
                                src={`${m3?.url}/favicon.ico`}
                                spare={`${config.domain}/favicon.ico`}
                                alt=''
                              />
                              <div className='web_navigation_article_box_list_item_box_right'>
                                <span>{m3?.name}</span>
                                <span title={m3?.content}>{m3?.content}</span>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  )
                }
              </div>
            )
          }
        </article>
      </main>
      {/* 弹窗 */}
      <>
      {/* 添加网站弹窗 */}
      <YzModalAddWeb
        open={isModalOpenAdd}
        token={token}
        onCancel={() => setIsModalOpenAdd(false) }
      />
      {/* 添加网站分类弹窗 */}
      <YzModalAddSort
        open={isModalOpenAddSort}
        token={token}
        onCancel={() => setIsModalOpenAddSort(false) }
      />
      {/* 编辑网站分类弹窗 */}
      <YzModalEditSort
        open={isModalOpenEditSort}
        nameEditSort={nameEditSort}
        setNameEditSort={setNameEditSort}
        onClick={editSortClick}
        onCancel={() => setIsModalOpenEditSort(false) }
      />
      {/* 编辑网站弹窗 */}
      <YzModalEditWeb
        open={isModalOpenEdit}
        token={token}
        nameEdit={nameEdit}
        linkEdit={linkEdit}
        sortEdit={idSortEdit}
        setNameEdit={setNameEdit}
        setLinkEdit={setLinkEdit}
        setSortEdit={setIdSortEdit}
        onClick={editClick}
        onCancel={() => setIsModalOpenEdit(false) }
      />
      {/* 删除网站弹窗 */}
      <YzModalRemoveWeb
        open={isModalOpenRemove}
        nameRemove={nameRemove}
        onClick={removeClick}
        onCancel={() => setIsModalOpenRemove(false) }
      />
      {/* 删除网站分类弹窗 */}
      <YzModalRemoveSort
        open={isModalOpenRemoveSort}
        nameRemove={nameRemove}
        onClick={removeSortClick}
        onCancel={() => setIsModalOpenRemoveSort(false) }
      />
      </>
    </div>
  )
}

export default WebNavigationMain