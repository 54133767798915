import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'

const Footer = () => {
  const navigate = useNavigate()
  // 系统 
  const commonConfig = useSelector(state => state?.App?.commonConfig) // 系统配置
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/navigation/listByPosition"+`?t=${new Date().getTime()}`, { position: 2 });
      if (res.status === 10000) {
          setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const [contact, setContact] = useState([]) // 联系方式
  const [code, setCode] = useState([]) // 联系我们二维码
  const getContact = async () => {  // 获取关于我们联系方式列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'footer_contact_us' });
      if (res.status === 10000) {
        setContact(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getCode = async () => {  // 获取关于我们二维码
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/list"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
        let target = res?.data?.filter(f => {
          return f?.key === 'index_code_upload'
        })
        setCode(target)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getContact()
    getCode()
  }, [])

  return (
    <footer className='footer'>
      <div className='footer_box'>
        <div className='box'>
          <div className='footer_menus'>
            {
              data &&
              data?.length > 0 &&
              data?.map((m1, index1) =>
                <div className='footer_menus_item' key={'footer_menus_item_'+index1}>
                  <span className='footer_menus_item_title'>{m1?.name}</span>
                  {
                    m1?.son_navigation &&
                    m1?.son_navigation?.length > 0 &&
                    m1?.son_navigation?.map((m2, index2) => 
                      <span
                        className='footer_menus_item_link' key={'footer_menus_item_link_'+index2}
                        onClick={() => {
                          navigate(m2?.link)
                        }}
                      >
                        { m2?.name }
                      </span>
                    )
                  }
                </div>
              )
            }
            {/* 联系我们 */}
            {/* <div className='footer_menus_item footer_margin_right_156'>
              <span className='footer_menus_item_title'>{contact && contact?.name}</span>
              {
                contact &&
                contact?.Gallery_list &&
                contact?.Gallery_list?.length > 0 &&
                contact?.Gallery_list?.map((m, index) =>
                  <div key={'footer_menus_item_link_'+index} className='footer_menus_item_link'>
                    <Image src={m?.pic} alt='' />
                    <span className='footer_menus_item_link_text'>
                      { m?.content }
                    </span>
                  </div>
                )
              }
            </div> */}
          </div>
          <div className='footer_qr_code'>
            {
              code &&
              code?.length > 0 &&
              code[0].Gallery_list &&
              code[0].Gallery_list?.length > 0 &&
              code[0].Gallery_list?.map((m, index) =>
                <div
                  key={'footer_qr_code_item_'+index}
                  className={`footer_qr_code_item ${index!==0 && 'margin_left_60px'}`}
                >
                  <Image
                    className='footer_qr_code_img'
                    src={m?.pic}
                    alt=''
                  />
                  <span className='footer_qr_code_text'>{m?.title}</span>
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className='footer_bottom'>
        <div className='box'>
          <Image className='footer_bottom_img' src={require('./assets/icon_icp@2x.png')} alt='' />
          {
            commonConfig?.web_garecordnum !=='' &&
            <span
              className='footer_bottom_text'
              onClick={() => {
                window.open(`https://www.beian.gov.cn/portal/index.do`, '_blank')
              }}
            >
              {commonConfig?.web_garecordnum !==''?commonConfig?.web_garecordnum:''}
            </span>
          }
          {
            commonConfig?.web_recordnum !=='' &&
            <>
              <span className='footer_divider'>｜</span>
              <span
                className='footer_bottom_text'
                onClick={() => {
                  window.open(`https://beian.miit.gov.cn/#/Integrated/index`, '_blank')
                }}
              >
                {commonConfig?.web_recordnum !==''?commonConfig?.web_recordnum:''}
              </span>
            </>
          }
          {
            commonConfig?.copyright_imformation !=='' &&
            <>
              <span className='footer_divider'>｜</span>
              <span className='footer_bottom_text'>
                {commonConfig?.copyright_imformation !==''?commonConfig?.copyright_imformation:''}
              </span>
            </>
          }
        </div>
      </div>
    </footer>
  )
}

export default Footer