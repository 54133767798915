// 其他路由项
const routers = [
  { title: '资讯详情', link: '/product/details' },
  { title: '搜索结果', link: '/product/search' },
  { title: '登录', link: '/login' },
  { title: '微信登录', link: '/login/wechat' },
  { title: '绑定手机号', link: '/login/tel_binding' },
  { title: '找回密码', link: '/login/password' },
  { title: '注册', link: '/register' },
  { title: '个人中心', link: '/personal' },
  { title: '注册协议', link: '/treaty' },
  { title: '业务布局', link: '/about_us/business' }
]
export const setDocumentTitle = (pathname, menuList, defaultTitle='清宜数科') => {
  let target = menuList?.map(item => {
    return {
      title: item?.title ?? '',
      link: item?.link ?? '/'
    }
  })
  if(
    [...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title) &&
    [...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)?.length > 0
  ) {
    if ([...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)[0]==='首页') {
      document.title=defaultTitle
    } else {
      document.title=`${[[...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)]}-${defaultTitle}`
    }
  } else {
    document.title=defaultTitle
  }
}