import { useState, useEffect } from 'react'
import Header from '../../components/pc/Header'
import ProductSection from './components/pc/ProductSection'
import HeaderMobile from '../../components/mobile/HeaderMobile'
import ProductSectionMobile from './components/mobile/ProductSectionMobile'
import { flexibleWidth } from '../../utils/flexible'
import './index.less'

const Product = () => {
  let [plat, setPlat] = useState(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
  // 页面配置
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth) // 页面宽度
  useEffect(() => { // 页面宽度变化
    flexibleWidth()
    setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    setWindowWidth(document.documentElement.clientWidth)
    window.addEventListener('resize', () => {
      flexibleWidth()
      setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      setWindowWidth(document.documentElement.clientWidth)
    }, true)
  }, [])

  return (
    <div className='product'>
      {
        !plat && windowWidth>540 ?
          <>
            <Header />
            <ProductSection />
          </> :
          <>
            <HeaderMobile />
            <ProductSectionMobile />
          </>
      }
    </div>
  )
}

export default Product