import { useState, useEffect } from 'react'
import { message } from 'antd'
import ScrollingTextBox from '../../../../../components/pc/ScrollingTextBox'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeIntroduce = () => {
  const [content, setContent] = useState()
  const [loading, setLoading] = useState(false)
  const getContent = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'enterprise_profile' });
      if (res.status === 10000) {
        setContent(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getContent()
  }, [])

  return (
    <div className='home_introduce'>
      <div className='main_box'>
        <div className='title'>
          <span>{ content && content?.name }</span>
          <span>Introduction</span>
        </div>
        <div className='content'>
          <div className='map'>
            <Image className='map_img' src={require('./assets/img_home_map@2x.png')} alt='' />
            <div className='map_lab_box'>
              <div className='map_label'>
                <Image src={require('./assets/icon_home_map_star@2x.png')} alt='' />
                <span>公司分布</span>
              </div>
              <div className='map_label margin_left'>
                <div className='dot'/>
                <span>客户分布</span>
              </div>
            </div>
          </div>
          <div className='information'>
            <span className='label'>
              { content && content?.value }
            </span>
            <div className='icon_list'>
              {
                content &&
                content?.Gallery_list &&
                content?.Gallery_list?.length > 0 &&
                content?.Gallery_list?.map((m,index) => 
                  <div className='icon_item' key={'icon_item_'+index}>
                    <div
                      className='icon_box'
                      style={{
                        paddingLeft: index%2!==0?'.0625rem':0,
                        paddingRight: index%2===0?'.0625rem':0
                      }}
                    >
                      <Image className='icon_img' src={m?.pic} alt='' />
                      <div style={{ marginTop: '.0625rem' }}>
                        <ScrollingTextBox
                          open={true}
                          start={() => {
                            let str = ''
                            for(let i=0; i<m?.content?.toString()?.length; i++) {
                              str = str + '0'
                            }
                            return str
                          }}
                          end={m?.content}
                        />
                      </div>
                      {/* <span className='icon_title'>{ m?.content }</span> */}
                      <span className='icon_content'>{ m?.title }</span>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeIntroduce