import { createSlice } from '@reduxjs/toolkit'
import qs from 'qs'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    token: localStorage.getItem('token') &&
           localStorage.getItem('token') !=='' ? localStorage.getItem('token') : null,
    commonConfig: localStorage.getItem('commonConfig') &&
                  localStorage.getItem('commonConfig') !== '' ? qs.parse(localStorage.getItem('commonConfig')) : null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
      localStorage.setItem('token', action.payload)
    },
    setCommonConfig: (state, action) => {
      state.commonConfig = action.payload
      localStorage.setItem('commonConfig', qs.stringify(action.payload))
    },
  }
})

export const {
  setToken,
  setCommonConfig,
} = appSlice.actions

export default appSlice.reducer