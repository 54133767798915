import { useLocation } from 'react-router-dom'
import QRCode from 'qrcode.react'
import config from '../../../../../config'
import Image from '../../../../../components/Image'
import './index.less'

const PDBuoyBox = ({data}) => {
  const location = useLocation()
  const code = config.domain+location.pathname+location.search
  return (
    <div className='pdb_buoy_box'>
      <span>分享至</span>
      <div className='btn'>
        <Image src={require('./assets/icon_wchat@2x.png')} alt='' />
        <div className='code'>
          <div className='code_box'>
            <QRCode value={code} />
            <span>微信扫一扫</span>
            <span>分享至朋友圈</span>
          </div>
        </div>
      </div>
      <div
        className='btn btn_wb'
        title='分享至微博'
        onClick={() => {
          let httpConfig = 'https://service.weibo.com/share/share.php?url='
          httpConfig += code
          httpConfig += '&title='+data.title
          httpConfig += '&pic='+data.cover
          window.open(httpConfig, '_blank')
        }}
      >
        <Image src={require('./assets/icon_weibo@2x.png')} alt='' />
      </div>
    </div>
  )
}

export default PDBuoyBox