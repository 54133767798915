import Image from '../../../../../components/Image'
import './index.less'

const AboutUsIntroduce = ({ data }) => {
  return (
    <div className='about_us_introduce'>
      <div className='about_us_introduce_left'>
        <div className='about_us_introduce_left_content'>
          {
            data &&
            data?.Gallery_list &&
            data?.Gallery_list?.length > 0 &&
            data?.Gallery_list?.map((m, index) =>
              <div className='about_us_introduce_left_item' key={'about_us_introduce_left_item_'+index}>
                <Image
                  className='about_us_introduce_left_img'
                  src={m?.pic}
                  alt=''
                />
                <div className='about_us_introduce_left_box'>
                  <span className='about_us_introduce_left_title'>
                    { m?.content }
                  </span>
                  <span className='about_us_introduce_left_vice_title'>
                    { m?.title }
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className='about_us_introduce_right'>
        <span className='about_us_introduce_right_title'>
          {data?.name}
        </span>
        <p className='about_us_introduce_right_content'>
          {`${data?.value}`}
        </p>
      </div>
    </div>
  )
}

export default AboutUsIntroduce