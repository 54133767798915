import { useState, useEffect } from 'react'
import { message, Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import moment from 'moment'
import './index.less'

const ProductSortMobile = ({
  goToTop=() => {}
}) => {
  const navigate = useNavigate()
  // const token = useSelector((state) => state?.App?.token)

  const [page, setPage] = useState(1)

  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [category_id] = menuList.filter(f => f.title === '清宜资讯').map(m => m.category_id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/article/list"+`?t=${new Date().getTime()}`, { category_id, page: 1, pers: 6 });
      if (res.status === 10000) {
        setData(res)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page])

  return (
    <>
      <div className='product_sort_m'>
        {/* 展示列表 */}
        {
          data &&
          data?.data?.length > 0 &&
          <div className='list'>
            {
              data?.data?.map((m, index) =>
                <div
                  key={'ssm_item_'+index}
                  className={`item ${index===0?'padding_top':''}`}
                  onClick={() => {
                    navigate('/product/details?id='+m?.id)
                  }}
                >
                  <Image src={m?.cover} alt='' />
                  <span className='item_title'>{m?.title}</span>
                  <span className='item_content'>{m?.summary}</span>
                  <div className='item_box'>
                    <div className='left'>
                      <Image src={require('./assets/icon_time@3x.png')} alt='' />
                      <span>{moment(m?.release_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                    <div className='btn'>
                      <span>查看更多</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        }
        {/* 分页器 */}
        {
          data &&
          data?.length > 0 &&
          <div className='pagination'>
            <Pagination
              current={page}
              total={data?.count}
              pageSize={6}
              showQuickJumper={true}
              showSizeChanger={false}
              hideOnSinglePage={true}
              onChange={(pageNumber) => {
                setPage(pageNumber)
                goToTop()
              }}
            />
          </div>
        }
      </div>
      {
        data &&
        data?.length === 0 &&
        <div style={{ flex: 1 }}>
          <YzEmptyMobile />
        </div>
      }
    </>
  )
}

export default ProductSortMobile