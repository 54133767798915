import { useState, useEffect } from 'react'
import './index.less'

const ScrollingTextBox = ({
  open=false,
  start='0',
  end=''
}) => {

  const [isAnima, setIsAnima] = useState(true)
  const [num, setNum] = useState('0')
  const timerNumber = (value) => {
    if(new RegExp(/(.+(?=[+]$))/).test(value)) {
      setIsAnima(false)
    } else {
      setIsAnima(true)
      setNum(value)
    }
  }
  useEffect(() => {
    if(open) timerNumber(end)
    else setNum(start)
  }, [open])

  return (
    <div className='scrolling_text_box'>
      {
        isAnima
        ?
        num.toString().split('') &&
        num.toString().split('')?.length > 0 &&
        num.toString().split('')?.map((m, index) => 
          <div className='box_item' key={'box_item_'+index}>
            <span style={{ transform: `translate(-50%, -${m*10}%)` }}>
              0123456789
            </span>
          </div>
        )
        :
        <span className='default_title'>
          {end}
        </span>
      }
    </div>
  )
}

export default ScrollingTextBox