import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Pagination, message } from 'antd'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import moment from 'moment'
import './index.less'

const SearchSortMobile = ({
  goToTop=() => {}
}) => {
  const location = useLocation()
  // const token = useSelector((state) => state?.App?.token)

  const [page, setPage] = useState(1)
  const [data, setData] = useState([]) // 搜索结果列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取搜索结果列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/search'+`?t=${new Date().getTime()}`, {
        keyword: location?.search ? decodeURI(location?.search.substring(5)) : '',
        page,
        pers: 6
      })
      if (res.status === 10000) {
        setData(res)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page, location?.search])

  return (
    <>
      <div className='search_sort_m'>
        <span className='title'>为您搜到以下结果：</span>
        {
          data &&
          data?.data?.length > 0 &&
          <div className='list'>
            {
              data?.data?.map((m, index) =>
                <div key={'ssm_item_'+index} className='item' style={{ marginTop: index===0 && 0 }}>
                  <Image src={m?.cover} alt='' />
                  <span className='item_title'>{m?.title}</span>
                  <span className='item_content'>{m?.summary}</span>
                  <div className='item_box'>
                    <div className='left'>
                      <Image src={require('./assets/icon_time@3x.png')} alt='' />
                      <span>{moment(m?.release_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                    <div className='btn'>
                      <span>查看更多</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        }
        <div className='pagination'>
          {
            data &&
            data?.length > 0 &&
              <Pagination
                current={page}
                total={data?.count}
                pageSize={6}
                showQuickJumper={true}
                showSizeChanger={false}
                hideOnSinglePage={true}
                onChange={(pageNumber) => {
                  setPage(pageNumber)
                  goToTop()
                }}
              />
          }
        </div>
      </div>
      {
        data &&
        data?.length === 0 &&
        <div style={{ flex: 1 }}>
          <YzEmptyMobile backgroundColor='#FAFAFA' />
        </div>
      }
    </>
  )
}

export default SearchSortMobile