import { useEffect, useState } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const AboutHonorMobile = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/list"+`?t=${new Date().getTime()}`)
      if (res.status === 10000) {
        let target = res?.data?.filter(f => {
          return f?.key === 'about_certificate' ||
          f?.key === 'about_qualifications' ||
          f?.key === 'about_patent'
        })
        setData(target)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='about_honor_m'>
      <span className='title'>
        {data?.name ?? '荣誉资质'}
      </span>
      <div className='about_honor_img'>
        <Image src={require('./assets/t_img_home_qualification@3x.png')} alt='' />
      </div>
      {
        data && data?.length > 0 &&
        <div className='box1'>
          <span>{ data[0] && data[0].name }</span>
          <div className='list'>
          {
            data[0]?.Gallery_list && data[0]?.Gallery_list?.length > 0 &&
            data[0]?.Gallery_list?.map((item,index) =>
            <div className='item' key={'item_1_'+index}>
              <span className='yuandian'/>
              <span className='item_title'>{item?.title}</span>
            </div>
          )}
          </div>
        </div>
      }
      {
        data && data?.length > 0 &&
        <div className='box1 margin_top'>
          <span>{ data[1] && data[1].name }</span>
          <div className='list'>
          {
            data[1]?.Gallery_list && data[1]?.Gallery_list?.length > 0 &&
            data[1]?.Gallery_list?.map((item,index) =>
            <div className='item' key={'item_2_'+index}>
              <span className='yuandian'/>
              <span className='item_title'>{item?.title}</span>
            </div>
          )}
          </div>
        </div>
      }
      {
        data && data?.length > 0 &&
        <div className='box2 margin_top'>
          <span>{ data[2] && data[2].name }</span>
          <div className='list'>
          {
            data[2]?.Gallery_list && data[2]?.Gallery_list?.length > 0 &&
            data[2]?.Gallery_list?.map((item,index) =>
            <div
              className={`item ${index%2===0?'padding_right':'padding_left'}`}
              key={'item_3_'+index}
            >
              <span className='yuandian'/>
              <span className='item_title'>{item?.title}</span>
            </div>
          )}
          </div>
        </div>
      }
    </div>
  )
}

export default AboutHonorMobile