import { useEffect, useState } from 'react'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const AUBTeam = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/team/list"+`?t=${new Date().getTime()}`)
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='aub_team'>
      <div className='main_box'>
        <div className='content'>
          <div className='information'>
            <span className='title'>团队展示</span>
            <div className='list'>
              {
                data && data?.length > 0 &&
                data?.map((m,index) =>
                  <div className={`item`} key={'item_'+index}>
                    <div className='item_box'>
                      <Image src={m?.pic} alt='' />
                      <div className='item_content'>
                        <span>{m?.name}</span>
                        <span>{m?.introduct}</span>
                        <div className='biaoji' />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AUBTeam