import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeNews = () => {
  const navigate = useNavigate()
  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [category_id] = menuList.filter(f => f.title === '清宜资讯').map(m => m.category_id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/article/list"+`?t=${new Date().getTime()}`, { category_id, page: 1, pers: 3 });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='home_new'>
      <div className='main_box'>
        <div className='title'>
          <span>新闻动态</span>
          <span>News</span>
        </div>
        <div className='content'>
          <div className='map'>
            <Image className='map_img' src={require('./assets/img_news@2x.png')} alt='' />
          </div>
          <div className='information'>
            <div className='icon_list'>
              {
                data &&
                data?.length > 0 &&
                data?.map((m,index) => 
                  <div
                    className='icon_item' key={'icon_item_'+index}
                    onClick={() => {
                      navigate('/product/details?id='+m?.id)
                    }}
                  >
                    <div className='icon_box'>
                      <Image className='icon_img' src={m?.cover} alt='' />
                      <span className='icon_title'>{ m?.title }</span>
                    </div>
                  </div>
                )
              }
            </div>
            {data && data?.length > 0 &&
            <div
              className='btn'
              onClick={() => {
                navigate('/product')
              }}
            >
              <span>查看更多</span>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeNews