import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { setMenuList } from '../../../store/menu'
import { setToken } from '../../../store/app'
import { setUserInfo } from '../../../store/user'
import { setDocumentTitle } from '../../../utils/setDocumentTitle'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'

const HeaderMobile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  // 系统 
  const commonConfig = useSelector(state => state?.App?.commonConfig) // 系统配置
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo) // 用户信息
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  // 网页标题
  useEffect(() => {
    setDocumentTitle(location?.pathname, menuList)
  }, [location])
  const [activeIndex, setActiveIndex] = useState(-1)

  // 搜索
  const [searchValue, setSearchValue] = useState('') // 搜索框内容
  const [isSearch, setIsSearch] = useState(false) // 是否弹出搜索框
  useEffect(() => { // 点击空白处收起搜索框
    if(isSearch) {
      window.addEventListener('click', () => {
        setIsSearch(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
  }, [isSearch])

  // 菜单
  const [isMenu, setIsMenu] = useState(false) // 是否弹出菜单
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/navigation/listByPosition"+`?t=${new Date().getTime()}`, { position: 1 });
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            key: item?.id,
            title: item?.name ?? '导航项',
            link: item?.link ?? '/',
            backgroud: item?.backgroud,
            category_id: item?.category_id,
            son_navigation: item?.son_navigation
          }
        })
        dispatch(setMenuList(target))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
    <header className='header_mobile'>
      <div
        className='logo'
        onClick={() => {
          navigate('/')
        }}
      >
        {
          commonConfig && commonConfig.web_logo &&
          <Image className='logo_img' src={commonConfig.web_logo} alt='' />
        }
        {/* <Image className='logo_img' src={require('./assets/logo@3x.png')} alt='' /> */}
      </div>
      <div className='right'>
        {/* <div className='login'>
          {
            !userInfo ?
            <>
              <span
                className='btn_login'
                onClick={() => {
                  navigate('/login')
                }}
              >
                登录
              </span>
              <div className='divider' />
              <span
                className='btn_register'
                onClick={() => {
                  navigate('/register')
                }}
              >
                注册
              </span>
            </> :
            <span
              className='btn_username'
              onClick={() => {
                navigate('/personal')
              }}
            >
              { userInfo?.user_name !== '' ? userInfo?.user_name : '用户名'  }
            </span>
          }
        </div> */}
        <div
          className='btn_search'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setIsSearch(true)
          }}
        >
          <Image src={require('./assets/icon_top_search@3x.png')} alt='' />
        </div>
        <div
          className='btn_menu'
          onClick={() => { setIsMenu(true) }}
        >
          <Image src={require('./assets/t_icon_top_menu@3x.png')} alt='' />
        </div>
      </div>
      {/* 搜索框 */}
      <div
        className={isSearch?'search_box search_active':'search_box'}
        onClick={(e) => { e.stopPropagation() }}
      >
        <div className='search_main'>
          <div className='search_input'>
            <input
              type={'text'}
              placeholder='请输入关键词'
              value={searchValue}
              onInput={(e) => { setSearchValue(e.target.value) }}
            />
          </div>
          <div
            className='btn_search'
            onClick={() => {
              if (searchValue==='') {
                return message.warning({ content: '请输入搜索内容', style: { marginTop: '10vh' } })
              }
              navigate('/product/search?key='+searchValue)
              setIsSearch(false)
            }}
          >
            <Image className='btn_search_img' src={require('./assets/icon_top_search_r_t@3x.png')} alt='' />
          </div>
        </div>
      </div>
      {isSearch&&<div className='search_mask'></div>}
      {/* 菜单 */}
      <div
        className={`menu_box ${isMenu && 'menu_active'}`}
        onClick={(e) => { e.stopPropagation() }}
      >
        <header className='header_mobile_m'>
          <div className='logo'>
            {
              commonConfig && commonConfig.web_logo &&
              <Image className='logo_img' src={commonConfig.web_logo} alt='' />
            }
            {/* <Image className='logo_img' src={require('./assets/logo@3x.png')} alt='' /> */}
          </div>
          <div
            className='btn_close'
            onClick={() => { setIsMenu(false) }}
          >
            <Image src={require('./assets/t_icon_top_close@3x.png')} alt='' />
          </div>
        </header>
        <div className='menu_list_m'>
          {
            menuList &&
            menuList?.length > 0 &&
            menuList?.map((m, index) =>
            <div key={'menu_item_m_'+index}>
              <div
                className={
                  `menu_item_m
                  ${location?.pathname === m?.link && 'menu_item_m_active'}
                  ${location?.pathname === '/personal' && m?.link === '/' && 'menu_item_m_active'}
                  ${m?.link !== '/' && new RegExp(`^(${m?.link})`).test(location?.pathname) && 'menu_item_m_active'}`
                }
                onClick={() => {
                  const [link] = menuList.filter(f=>f.link===m.link).map(item=>item.link)
                  setActiveIndex(menuList.map(item=>item.link).indexOf(link))
                  if(m?.son_navigation) return;
                  navigate(m?.link)
                }}
              >
                <span>{m?.title}</span>
                {m?.son_navigation && m?.son_navigation?.length > 0 &&
                  <Image
                    src={require(activeIndex===index?'./assets/icon_more_t_t@3x.png':'./assets/icon_more_b_t@3x.png')} alt=''
                  />
                }
              </div>
              <div className={`menu_item_list ${activeIndex===index?'active':''}`}>
                {
                  m?.son_navigation && m?.son_navigation?.length > 0 &&
                  m?.son_navigation?.map((mm,mindex) =>
                    <div
                      className='menu_item_list_item' key={'menu_item_list_item_'+mindex}
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(mm?.link)
                      }}
                    >
                      { mm.name }
                    </div>
                  )
                }
              </div>
            </div>
            )
          }
        </div>
        {
          userInfo &&
          <div className='menu_list_m menu_border_top'>
            <div
              className='menu_item_m'
              onClick={() => {
                setIsMenu(false)
                navigate('/personal')
              }}
            >
              个人中心
            </div>
            <div
              className='menu_item_m'
              onClick={() => {
                // 清空当前用户信息，回到首页
                dispatch(setToken(null))
                dispatch(setUserInfo(null))
                setIsMenu(false)
                navigate('/')
              }}
            >
              退出登录
            </div>
          </div>
        }
      </div>
    </header>
    <div className='header_mobile_ccupy' />
    </>
  )
}

export default HeaderMobile