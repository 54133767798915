import { useState, useEffect, useRef } from 'react' 
import { message } from 'antd'
import { useSelector } from 'react-redux'
import YzBackground from '../../../../../components/pc/YzBackground'
import YzExhibitionRow from '../../../../../components/pc/YzExhibitionRow'
import Footer from '../../../../../components/pc/Footer'
import BuoyBox from '../../../../../components/pc/BuoyBox'
import YzEmpty from '../../../../../components/pc/YzEmpty'
import HTTP from '../../../../../utils/Http'
import './index.less'

const SolutionSection = () => {
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '解决方案').map(m => m.backgroud)

  const [data, setData] = useState([]) // 解决方案列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取解决方案列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/solution/list"+`?t=${new Date().getTime()}`)
      if (res.status === 10000) {
          setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='solution_section'>
      <div className='solution_box'>
        <YzBackground
          title='为您定制专属解决方案'
          content='灵活产品组合，为您定制专属解决方案'
          backgroundImage={backgroud}
        />
        {
          data &&
          data?.length > 0 ?
          data?.map((m, index) =>
            <YzExhibitionRow
              key={'yz_exhibition_row_'+index}
              index={index}
              title={m?.title}
              viceTitle={m?.content}
              icon={m?.pic}
              backgroundColors={['#FAFAFA', '#FFF']}
            />
          ) :
          <div style={{ flex: 1 }}>
            <YzEmpty backgroundColor='#FAFAFA' />
          </div>
        }
        <Footer />
        <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default SolutionSection