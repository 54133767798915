import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Pagination, message } from 'antd'
// import { useSelector } from 'react-redux'
import YzEmpty from '../../../../../components/pc/YzEmpty'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import moment from 'moment'
import './index.less'

const SearchSort = ({
  goToTop=() => {}
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  // const token = useSelector((state) => state?.App?.token)

  const [page, setPage] = useState(1)
  const [data, setData] = useState([]) // 搜索结果列表
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取搜索结果列表
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/search'+`?t=${new Date().getTime()}`, {
        keyword: location?.search ? decodeURI(location?.search.substring(5)) : '',
        page,
        pers: 6
      })
      if (res.status === 10000) {
        setData(res)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page, location?.search])
  return (
    <>
    <div className='search_sort'>
      <span className='search_sort_label'>为您搜到以下结果：</span>
      {/* 展示列表 */}
      {
        data &&
        data?.data?.length > 0 &&
        <div className='search_sort_list'>
          {
            data?.data?.map((m, index) =>
              <div
                className={`search_sort_item`}
                key={'search_'+index}
                onClick={() => {
                  navigate('/product/details?id='+m?.id)
                }}
              >
                <div className={`search_sort_item_box`}>
                  <Image src={m?.cover} alt='' />
                  <div className='search_box'>
                    <span className='search_title'>{m?.title}</span>
                    <span className='search_content' title={m?.summary}>{m?.summary}</span>
                    <div className='search_btn'>
                      <div className='left'>
                        <Image src={require('./assets/icon_time@2x.png')} />
                        <span>{moment(m?.release_at).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                      <div className='btn' onClick={() => {}}>
                        <span>查看更多</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          </div>
      }
      {/* 分页器 */}
      {
        data &&
        data?.length > 0 &&
        <div className='search_sort_pagination'>
          <Pagination
            current={page}
            total={data?.count}
            pageSize={6}
            showQuickJumper={true}
            showSizeChanger={false}
            hideOnSinglePage={true}
            onChange={(pageNumber) => {
              setPage(pageNumber)
              goToTop()
            }}
          />
        </div>
      }
    </div>
    {
      data &&
      data?.length === 0 &&
      <div style={{ flex: 1 }}>
        <YzEmpty backgroundColor='#FAFAFA' />
      </div>
    }
    </>
  )
}

export default SearchSort