import { useNavigate } from 'react-router-dom'
import './index.less'

const PDHeader = () => {
  const navigate = useNavigate()
  return (
    <header className='pdh_header'>
      <div className='pdh_header_box'>
        <span onClick={() => { navigate('/') }}>首页</span>
        <div className='to_right'>&gt;</div>
        <span onClick={() => { navigate('/product') }}>清宜资讯</span>
        <div className='to_right'>&gt;</div>
        <span className='active'>正文</span>
      </div>
    </header>
  )
}

export default PDHeader