import { useState, useEffect } from 'react'
import { Pagination, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HTTP from '../../../../../utils/Http'
import YzEmpty from '../../../../../components/pc/YzEmpty'
import Image from '../../../../../components/Image'
import moment from 'moment'
import './index.less'

const ProductSort = ({
  goToTop=() => {}
}) => {
  const navigate = useNavigate()
  // const token = useSelector((state) => state?.App?.token)

  const [page, setPage] = useState(1)

  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [category_id] = menuList.filter(f => f.title === '清宜资讯').map(m => m.category_id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/article/list"+`?t=${new Date().getTime()}`, { category_id, page, pers: 6 });
      if (res.status === 10000) {
        setData(res)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [page])

  return (
    <>
      <div className='product_sort'>
        <span className='product_sort_title'>新闻动态</span>
        {/* 展示列表 */}
        {
          data &&
          data?.data?.length > 0 &&
          <div className='product_sort_list'>
            {
              data?.data?.map((m, index) =>
                <div
                  className={`product_sort_item`}
                  key={'product_'+index}
                  onClick={() => {
                    navigate('/product/details?id='+m?.id)
                  }}
                >
                  <div className={`product_sort_item_box`}>
                    <Image src={m?.cover} alt='' />
                    <div className='product_box'>
                      <span className='product_title'>{m?.title}</span>
                      <span className='product_content' title={m?.summary}>{m?.summary}</span>
                      <div className='product_btn'>
                        <div className='left'>
                          <Image src={require('./assets/icon_time@2x.png')} />
                          <span>{moment(m?.release_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                        <div className='btn' onClick={() => {}}>
                          <span>查看更多</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        }
        {/* 分页器 */}
        {
          data && 
          data?.data?.length > 0 &&
          <div className='product_sort_pagination'>
            <Pagination
              current={page}
              total={data?.count}
              pageSize={6}
              showQuickJumper={true}
              showSizeChanger={false}
              hideOnSinglePage={true}
              onChange={(pageNumber) => {
                setPage(pageNumber)
                goToTop()
              }}
            />
          </div>
        }
      </div>
      {
        data &&
        data?.length === 0 &&
        <div style={{ flex: 1 }}>
          <YzEmpty backgroundColor='#FAFAFA' />
        </div>
      }
    </>
  )
}

export default ProductSort