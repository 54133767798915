import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
// import { setToken } from '../../../store/app'
// import { setUserInfo } from '../../../store/user'
import { setMenuList } from '../../../store/menu'
import { setDocumentTitle } from '../../../utils/setDocumentTitle'
import HTTP from '../../../utils/Http'
import Image from '../../Image'
import './index.less'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  // 系统 
  const commonConfig = useSelector(state => state?.App?.commonConfig) // 系统配置
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo) // 用户信息
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  // 网页标题
  useEffect(() => {
    setDocumentTitle(location?.pathname, menuList, commonConfig?.web_title)
  }, [location])

  // 搜索
  const [searchValue, setSearchValue] = useState('') // 搜索框内容
  const [isSearch, setIsSearch] = useState(false) // 是否弹出搜索框
  // 用户下拉菜单
  const [isUserMenu, setIsUserMenu] = useState(false) // 是否弹出用户下拉框
  useEffect(() => { // 点击空白处收起搜索框
    if(isSearch) {
      window.addEventListener('click', () => {
        setIsSearch(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
  }, [isSearch])
  useEffect(() => { // 点击空白处收起用户下拉框
    if(isUserMenu) {
      window.addEventListener('click', () => {
        setIsUserMenu(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
  }, [isUserMenu])

  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/navigation/listByPosition"+`?t=${new Date().getTime()}`, { position: 1 });
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            key: item?.id,
            title: item?.name ?? '导航项',
            link: item?.link ?? '/',
            backgroud: item?.backgroud,
            category_id: item?.category_id,
            son_navigation: item?.son_navigation
          }
        })
        dispatch(setMenuList(target))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <>
    <header className='header'>
      {/* 左侧 */}
      <div
        className='logo'
        onClick={() => {
          navigate('/')
        }}
      >
        {
          commonConfig && commonConfig.web_logo &&
          <Image className='logo_img' src={commonConfig.web_logo} alt='' />
        }
      </div>
      {/* 右侧 */}
      <div className='right'>
        {/* 菜单 */}
        <nav className='menu_list'>
          {
            menuList &&
            menuList?.length > 0 &&
            menuList?.map((m,index) =>
            <div
              key={'menu_list_'+index}
              className={
                `menu_item
                ${location?.pathname === m?.link && 'menu_active'}
                ${location?.pathname === '/personal' && m?.link === '/' && 'menu_active'}
                ${m?.link !== '/' && new RegExp(`^(${m?.link})`).test(location?.pathname) && 'menu_active'}`
              }
              onClick={() => {
                // if(m?.son_navigation) return;
                navigate(m?.link)
              }}
            >
              {m?.title}
              <div className={`menu_item_list ${m?.son_navigation?'active':''}`}>
                {
                  m?.son_navigation && m?.son_navigation?.length > 0 &&
                  m?.son_navigation?.map((mm,mindex) =>
                    <div
                      className='menu_item_list_item' key={'menu_item_list_item_'+mindex}
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(mm?.link)
                      }}
                    >
                      { mm.name }
                    </div>
                  )
                }
              </div>
            </div>
          )}
        </nav>
        {/* 其他 */}
        <div className='other'>
          <div className='oivider' style={{ marginLeft: '0 .0625rem' }}/>
          {/* 搜索 */}
          <div
            className='btn_search'
            onClick={(e) => { e.stopPropagation() }}
            onMouseOver={() => {
              setIsUserMenu(false)
              setIsSearch(true)
            }}
          >
            <Image
              className='btn_search_img'
              src={require('./assets/icon_top_search@2x.png')}
              alt=''
            />
          </div>
          {
            // !userInfo ?
            // <>
            //   <div
            //     className='login'
            //     onClick={() => {
            //       // navigate('/login')
            //       navigate('/login/wechat')
            //     }}
            //   >
            //     登录
            //   </div>
            //   <div className='oivider' style={{ margin: '0 .0938rem' }} />
            //   <div
            //     className='register'
            //     onClick={() => {
            //       // navigate('/register')
            //       navigate('/login/wechat')
            //     }}
            //   >
            //     注册
            //   </div>
            // </> :
            // <>
            //   <div
            //     className='login'
            //     onClick={(e) => {
            //       e.preventDefault()
            //       e.stopPropagation()
            //     }}
            //     onMouseOver={(e) => {
            //       e.preventDefault()
            //       e.stopPropagation()
            //       setIsSearch(false)
            //       setIsUserMenu(true)
            //     }}
            //   >
            //     { userInfo?.user_name !== '' ? userInfo?.user_name : '用户名'  }
            //     {/* 已登录下拉框 */}
            //     <div
            //       className={isUserMenu?'user_box user_active':'user_box'}
            //       onClick={(e) => {
            //         e.preventDefault()
            //         e.stopPropagation()
            //       }}
            //       onMouseOver={(e) => {
            //         e.preventDefault()
            //         e.stopPropagation()
            //       }}
            //     >
            //       <div
            //         className='user_box_item'
            //         onClick={() => {
            //           setIsUserMenu(false)
            //           navigate('/personal')
            //         }}
            //       >
            //         账号资料
            //       </div>
            //       <div className='user_box_divider'>
            //         <div className='user_box_divider_box' />
            //       </div>
            //       {/* <div
            //         className='user_box_item'
            //         onClick={() => {
            //           // 清空当前用户信息，回到首页
            //           dispatch(setToken(null))
            //           dispatch(setUserInfo(null))
            //           setIsUserMenu(false)
            //           navigate('/')
            //         }}
            //       >
            //         退出登录
            //       </div> */}
            //     </div>
            //   </div>
            //   <div className='oivider' style={{ margin: '0 .0938rem' }} />
            //   <div
            //     className='register'
            //     onClick={() => {
            //       // 清空当前用户信息，回到首页
            //       dispatch(setToken(null))
            //       dispatch(setUserInfo(null))
            //       setIsUserMenu(false)
            //       navigate('/')
            //     }}
            //   >
            //     退出
            //   </div>
            // </>
          }
        </div>
      </div>
      {/* 搜索框 */}
      <div
        className={isSearch?'search_box search_active':'search_box'}
        onClick={(e) => { e.stopPropagation() }}
      >
        <div className='search_input'>
          <input
            type={'text'}
            placeholder='请输入关键词'
            value={searchValue}
            onInput={(e) => { setSearchValue(e?.target?.value); }}
            onKeyUp={(e) => {
              if(e?.code === 'Enter' || e?.key === 'Enter') {
                if (searchValue==='') {
                  return message.warning({ content: '请输入搜索内容', style: { marginTop: '10vh' } })
                }
                navigate(`/product/search?key=${searchValue}`)
              }
            }}
          />
        </div>
        <div
          className='btn_search'
          onClick={() => {
            if (searchValue==='') {
              return message.warning({ content: '请输入搜索内容', style: { marginTop: '10vh' } })
            }
            navigate(`/product/search?key=${searchValue}`)
          }}
        >
          <Image className='btn_search_img' src={require('./assets/icon_top_search_r@2x.png')} alt='' />
        </div>
      </div>
    </header>
    <div className='header_ccupy' />
    </>
  )
}

export default Header
