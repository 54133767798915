import { Modal, message } from 'antd'
import Image from '../../../../../components/Image'
import './index.less'

const YzModalEditSortMobile = ({
  open=false,
  nameEditSort,
  setNameEditSort,
  onClick=() => {},
  onCancel=() => {}
}) => {
  const getSave = async () => {
    if (nameEditSort === '') return message.error({ content: '分类名称不能为空', style: { marginTop: '10vh' } })
    onClick()
  }

  return (
    <Modal
      className='yz_modal_add_web_m'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={() => {
        onCancel()
        setNameEditSort('')
      }}
    >
      <div className='modal_header_web'>
        <span className='modal_title_web'>
          编辑分类
        </span>
        <div className='modal_close_web' onClick={onCancel}>
          <Image className='modal_close_img_web' src={require('./assets/t_icon_top_close@3x.png')} alt='' />
        </div>
      </div>
      <div className='modal_body_web'>
        <div className='web_input_box'>
          <span>分类名称</span>
          <div className='web_input_box_main'>
            <input
              placeholder='输入分类名称'
              value={nameEditSort}
              onInput={(e) => { setNameEditSort(e?.target?.value) }}
            />
          </div>
        </div>
        <div className='web_btn' onClick={getSave}>
          <span>保存</span>
        </div>
      </div>
    </Modal>
  )
}

export default YzModalEditSortMobile