import { useState } from 'react'
import Image from '../../Image'
import YzModalMobile from '../YzModalMobile'
import './index.less'

const YzExhibitionRowMobile = ({
  index=0,
  title='',
  viceTitle='',
  icon='',
  backgroundColors=[],
  btnTitle='了解更多'
}) => {
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bg1, bg2] = backgroundColors

  return (
    <div className='yz_exhibition_row_m' style={{ backgroundColor: index%2===0?bg1:bg2 }}>
      <Image src={icon} alt='' />
      <div className='yz_content'>
        <span className='yz_exhibition_title'>
          { title }
        </span>
        <span className='yz_exhibition_vice_title'>
          { viceTitle }
        </span>
        <div className='btn' onClick={() => { setIsModalOpen(true) }}>
          <span>{ btnTitle }</span>
        </div>
      </div>
      {/* 立即咨询弹窗 */}
      <YzModalMobile open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default YzExhibitionRowMobile