import { useRef, useState, useEffect } from 'react'
import { Carousel, message } from 'antd'
import Image from '../../../../../components/Image'
import YzModal from '../../../../../components/pc/YzModal'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeSwiper = () => {
  // 轮播
  const uRef = useRef(null) // 页面轮播对象
  const onLeft = () => { // 上一张
    uRef.current.prev()
  }
  const onRight = () => { // 下一张
    uRef.current.next()
  }
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/carousel/carouselList"+`?t=${new Date().getTime()}`);
      if (res.status === 10000) {
          setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='home_swiper'>
      {/* 左箭头 */}
      {data && data?.length > 1 &&
      <div className='swiper_prev' onClick={onLeft}>
        <Image width='100%' height='100%' src={require('./assets/icon_more_left@2x.png')} alt='' />
      </div>}
      {/* 右箭头 */}
      {data && data?.length > 1 &&
      <div className='swiper_next' onClick={onRight}>
        <Image width={'100%'} height='100%' src={require('./assets/icon_more_right@2x.png')} alt='' />
      </div>}
      {/* 轮播图 */}
      <Carousel
        ref={uRef}
        autoplay // 自动播放
        autoplaySpeed={3000} // 播放速度
        easing='linear' // 运动曲线
        draggable // 鼠标滑动
        dots // 指示点
        fade={true} // 淡入淡出
        // pauseOnHover={true} // 鼠标在其上时是否悬停禁止自动播放(默认true)
      >
        {
          data &&
          data?.length > 0 &&
          data?.map((m, index) =>
            <div key={'swiper_item_'+index}>
              <div className='box'>
                {/* 图 */}
                <div
                  className='swiper_img_bg'
                  style={{ backgroundImage: `url(${m?.cover})` }}
                  onClick={() => {
                    if(m?.link && m?.link !== '') window.open(m?.link)
                  }}
                >
                  <div className='content'>
                    <span className='title'>
                      {m?.title ?? ''}
                    </span>
                    {m?.content &&
                    <div className='box_span'>
                      <span>
                        {`${m?.content}`}
                      </span>
                    </div>}
                    {
                      m?.title && m?.title !== '' &&
                      m?.content && m?.content !== '' &&
                      <div
                        className='btn_consult'
                        onClick={(e) => {
                          e.stopPropagation()
                          setIsModalOpen(true)
                        }}
                      >
                        <span>
                          立即咨询
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Carousel>
      {/* 立即咨询弹窗 */}
      <YzModal open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default HomeSwiper