import { useLocation } from 'react-router-dom'
import QRCode from 'qrcode.react'
import config from '../../../../../config'
import Image from '../../../../../components/Image'
import './index.less'

const PDBuoyBoxMobile = ({
  isShowTop=true,
  onTop=() => {},
  data
}) => {
  const location = useLocation()
  const code = config.domain + location.pathname + location.search
  return (
    <div className='pd_buoy_box_m'>
      <div className='btn_box'>
        {/* 客服按钮 */}
        <a
          className='btn'
          onClick={() => {  }}
          href='https://work.weixin.qq.com/kfid/kfc4e4b3fc70c01305d'
        >
          <Image src={require('./assets/icon_service_t@3x.png')} alt='' />
        </a>
        {/* 微信 */}
        <div
          className='btn btn_wx'
          onClick={() => {  }}
        >
          <Image src={require('./assets/icon_wchat@3x.png')} alt='' />
          <div className='code'>
            <div className='code_box'>
              <QRCode value={code} />
              <span>微信扫一扫</span>
              <span>分享至朋友圈</span>
            </div>
          </div>
        </div>
        {/* 微博 */}
        <div
          className='btn'
          onClick={() => {
            let httpConfig = 'https://service.weibo.com/share/share.php'
            httpConfig = httpConfig +'?title='+data.title
            httpConfig = httpConfig +'&pic='+data.cover
            httpConfig = httpConfig +'&url='+encodeURIComponent(code)
            console.log(httpConfig);
            window.open(httpConfig, '_blank')
          }}
        >
          <Image src={require('./assets/icon_weibo@3x.png')} alt='' />
        </div>
      </div>
      {/* 置顶按钮 */}
      <div
        className='btn_m btn_to_top_m'
        style={{ opacity: isShowTop?1:0 }}
        onClick={() => {
          if(!isShowTop) return;
          onTop()
        }}
      >
        <Image src={require('./assets/icon_top@3x.png')} alt='' />
      </div>
    </div>
  )
}

export default PDBuoyBoxMobile