import Image from '../../../../../components/Image'
import moment from 'moment'
import './index.less'

const PDMainMobile = ({ Current }) => {
  return (
    <>
    <main className='pd_main_m'>
      <div className='pd_main_box'>
        <div className='pd_main_box_header'>
          <span className='title'>{Current?.title}</span>
          <div className='time'>
            <Image src={require('./assets/icon_time@3x.png')} />
            <span>{moment(Current?.release_at).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          <span className='jianjie'>{Current?.summary}</span>
        </div>
        <div className='pd_main_box_content' dangerouslySetInnerHTML={{ __html: Current?.content }} />
      </div>
    </main>
    </>
  )
}

export default PDMainMobile