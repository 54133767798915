import Image from '../../../../../components/Image'
import './index.less'

const AboutCourseMobile = ({data}) => {
  return (
    <div
      className='about_course_m'
      style={{ backgroundImage: `url(${require('./assets/img_aboutUs_de_m@3x.png')})` }}
    >
      <span className='title'>
        {data?.name}
      </span>
      <span className='content'>
        {`${data?.value}`}
      </span>
      <div className='icon_list'>
        <Image src={require('./assets/img_aboutUs_de_mtext@3x.png')} alt='' />
      </div>
    </div>
  )
}

export default AboutCourseMobile