import { Modal } from 'antd'
import Image from '../../../../../components/Image'
import './index.less'

const YzModalRemoveWeb = ({
  open=false,
  nameRemove,
  onClick=() => {},
  onCancel=() => {}
}) => {

  return (
    <Modal
      className='yz_modal_add_web'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={() => {
        onCancel()
      }}
    >
      <div className='modal_header_web'>
        <span className='modal_title_web'>
          删除网站
        </span>
        <div className='modal_close_web' onClick={onCancel}>
          <Image className='modal_close_img_web' src={require('./assets/t_icon_top_close@2x.png')} alt='' />
        </div>
      </div>
      <div className='modal_body_web'>
        <div className='modal_body_web_content'>
          是否确认删除
          <span>{nameRemove}</span>
          该网站吗？
        </div>
        <div className='modal_body_web_btn'>
          <div className='web_btn left' onClick={onCancel}>
            <span>取消</span>
          </div>
          <div className='web_btn' onClick={onClick}>
            <span>删除</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default YzModalRemoveWeb