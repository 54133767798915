import { useState, useEffect, useRef } from 'react'
import HomeSwiperMobile from '../HomeSwiperMobile'
import HomeIntroduceMobile from '../HomeIntroduceMobile'
import HomeHonorMobile from '../HomeHonorMobile'
import HomeCustomMadeMobile from '../HomeCustomMadeMobile'
import HomePartnersMobile from '../HomePartnersMobile'
import HomeNewsMobile from '../HomeNewsMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import BuoyBoxMobile from '../../../../../components/mobile/BuoyBoxMobile'
import './index.less'
import HomeContactUsMobile from '../HomeContactUsMobile'

const HomeSectionMobile = () => {
  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='home_section_m'>
      <div className='home_section_m_box'>
        <HomeSwiperMobile />
        <HomeIntroduceMobile />
        <HomeHonorMobile />
        <HomeCustomMadeMobile />
        <HomePartnersMobile />
        <HomeNewsMobile />
        <HomeContactUsMobile />
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default HomeSectionMobile