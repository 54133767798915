import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import YzBackground from '../../../../../components/pc/YzBackground'
import SuccessfulCaseSort from '../SuccessfulCaseSort'
import Footer from '../../../../../components/pc/Footer'
import BuoyBox from '../../../../../components/pc/BuoyBox'
import './index.less'

const SuccessfulCaseSection = () => {
  // 菜单
  const menuList = useSelector((state) => state?.Menu?.menuList) // 菜单列表
  const [backgroud] = menuList.filter(f => f.title === '经典案例').map(m => m.backgroud)

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = () => { // 置顶事件
    let myTimer = setInterval(() => {
      boxRef.current.scrollTop -= 60
      if(boxRef.current.scrollTop<=0){
        boxRef.current.scrollTop = 0
        window.clearInterval(myTimer)
      }
    }, 10)
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='sc_section'>
      <div className='sc_section_box'>
        <YzBackground
          title='Classic Cases'
          content='经典案例'
          backgroundImage={backgroud}
          isShowButton={false}
        />
        <SuccessfulCaseSort goToTop={goToTop} />
        <Footer />
        <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default SuccessfulCaseSection