import Image from '../../../../../components/Image'
import './index.less'

const AboutUsIntroduceMobile = ({data}) => {
  return (
    <div className='about_us_introduce_m'>
      <span className='title'>
        {data?.name}
      </span>
      <span className='content'>
        {`${data?.value}`}
      </span>
      <div className='icon_list'>
        {
          data &&
          data?.Gallery_list &&
          data?.Gallery_list?.length > 0 &&
          data?.Gallery_list?.map((m, index) => 
            <div key={'icon_item_'+index} className='icon_item'>
              <Image className='icon_img' src={m?.pic} alt=''  />
              <div className='icon_box'>
                <span className='icon_title'>{m?.content}</span>
                <span className='icon_content'>{m?.title}</span>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default AboutUsIntroduceMobile