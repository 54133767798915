import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const HomeCustomMadeMobile = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/solution/coreBusiness"+`?t=${new Date().getTime()}`, { page: 1, pers: 7 });
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const [content, setContent] = useState()
  const getContent = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'core_business' });
      if (res.status === 10000) {
        setContent(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
    getContent()
  }, [])

  return (
    <div className='home_custom_made_m'>
      <div className='title'>
        <span>{ content && content?.name }</span>
        <span>Core Business</span>
      </div>
      <div className='list'>
        {
          data &&
          data?.length > 0 &&
          data?.map((m, index) => 
            <div
              key={'kcmm_item_'+index}
              className='item'
              onClick={() => {
                navigate('/custom_development')
              }}
            >
              <Image src={m?.pic} alt='' />
              <div className='item_content'>
                <span>{m?.title}</span>
                <span>{m?.content}</span>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default HomeCustomMadeMobile