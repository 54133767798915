import Image from '../../../../../components/Image'
import './index.less'

const AboutCourse = ({ data }) => {
  return (
    <div
      className='about_course'
      style={{ backgroundImage: `url(${require('./assets/img_aboutUs_de@2x.png')})` }}
    >
      <div className='about_course_header'>
        <span className='about_course_title'>
          { data?.name }
        </span>
        <span className='about_course_content'>
          {`${data?.value}`}
        </span>
        <div className='course_img'>
          <Image src={require('./assets/img_aboutUs_text@2x.png')} alt='' />
        </div>
      </div>
    </div>
  )
}

export default AboutCourse