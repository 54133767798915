import { useEffect, useState } from 'react'
import { message } from 'antd'
// import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import './index.less'

const AUBAdvantage = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/list"+`?t=${new Date().getTime()}`)
      if (res.status === 10000) {
        let target = res?.data?.filter(f => {
          return f?.key === 'skill' ||
          f?.key === 'experience' ||
          f?.key === 'media'
        })
        setData(target)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='aub_advantage'>
      <div className='main_box'>
        <div className='content'>
          <div className='information'>
            <span className='title'>核心优势</span>
            <div className='list'>
              {
                data && data?.length > 0 &&
                data?.map((m,index) =>
                <div
                  className={`item
                    ${activeIndex===index?'active':''}
                    ${index===0?'margin_left':''}`
                  }
                  key={'item_'+index}
                  onMouseEnter={() => {
                    if(activeIndex!==index) return setActiveIndex(index)
                  }}
                >
                  <div className='item_box'>
                    <span>{m?.name}</span>
                    <span>{m?.value}</span>
                    <div className='to_bottom_san' />
                  </div>
                </div>
                )
              }
            </div>
            <div className='tab_content'>
              {
                data && data?.length > 0 && data[activeIndex] &&
                <div className='tab_content_box' dangerouslySetInnerHTML={{
                  __html: data[activeIndex]?.content
                }}></div>
              }
            </div>
            {/* {activeIndex!==2&&
            <div className='tabs'>
              {
                data && data?.length > 0 && data[activeIndex] &&
                data[activeIndex]?.Gallery_list?.map((m,index) =>
                  <div className={`tabs_item ${index===0?'margin_top':''}`} key={'tabs_item_'+index}>
                    <div className='yuan' />
                    <span>{m?.content}</span>
                  </div>
                )
              }
            </div>} */}
            {/* {activeIndex===2&&
            <div className='tabs2'>
              {
                data && data?.length > 0 && data[activeIndex] &&
                data[activeIndex]?.Gallery_list?.map((m,index) =>
                  <div className={`tabs_item`} key={'tabs_item_'+index}>
                    <div className='tabs_item_box'>
                      <Image src={m?.pic} alt='' />
                    </div>
                  </div>
                )
              }
            </div>} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AUBAdvantage