import { useState } from 'react'
import Image from '../../Image'
import YzModal from '../YzModal'
import './index.less'

const YzExhibitionRow = ({
  index=0,
  title='',
  viceTitle='',
  icon='',
  backgroundColors=[],
  btnTitle='了解更多'
}) => {
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bg1, bg2] = backgroundColors

  return (
    <div className='yz_exhibition_row' style={{ backgroundColor: index%2===0?bg1:bg2 }}>
      <div
        className='yz_exhibition_box'
        // style={{ backgroundColor: index%2===0?bg1:bg2 }}
      >
        {
          index%2!==0 &&
          <div className='yz_exhibition_right1'>
            <Image className='yz_exhibition_img' src={icon} alt='' />
          </div>
        }
        <div className='yz_exhibition_left'>
          <span className='yz_exhibition_title'>
            { title }
          </span>
          <span className='yz_exhibition_vice_title'>
            { viceTitle }
          </span>
          <div className='btn' onClick={() => { setIsModalOpen(true) }}>
            <span>{ btnTitle }</span>
          </div>
        </div>
        {
          index%2===0 &&
          <div className='yz_exhibition_right2'>
            <Image className='yz_exhibition_img' src={icon} alt='' />
          </div>
        }
      </div>
      {/* 立即咨询弹窗 */}
      <YzModal open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default YzExhibitionRow